import React, { useEffect, useState } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Table,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { URLS } from "../../Url"
import Moment from "react-moment"

function PaymentReport() {
  const [isLoading, setIsLoading] = useState(true)

  const [Agent, setAgent] = useState([])
  useEffect(() => {
    AgentReport()
  }, [])

  const [userInCsv, setuserInCsv] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const AgentReport = () => {
    var token = datas
    const data = { fromDate: "", toDate: "" }

    axios
      .post(URLS.PaymentesReport, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setAgent(res.data.AllDetails)
        setuserInCsv(res.data.AllDetails)
        setIsLoading(false)
      })
  }
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Agent.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Agent.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetAgentFiliter()
  }

  const GetAgentFiliter = () => {
    var token = datas
    const data = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    }
    axios
      .post(URLS.PaymentesReport, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setAgent(res.data.AllDetails)
        setuserInCsv(res.data.AllDetails)
        hidefilter()
        setfilters("")
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const csvReport = {
    filename: "Payment_report",
    data: userInCsv,
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A1"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)

    const headers = [
      [
        "SlNo",
        "Customer Code",
        "Customer Name",
        "Customer Phone",
        "Customer Address",
        "Customer PinCode",
        "Franchise Id",
        "Franchise Name",
        "Franchise Phone",
        "Franchise Email",
        "Booking Date",
        "Booking Type",
        "Booking Amount",
        "Payment Type",
        "Transaction Id",
      ],
    ]

    const data = Agent.map((elt, i) => [
      i + 1,
      elt.userCode,
      elt.customerName,
      elt.phone,
      elt.address,
      elt.pinCode,
      elt.franchiseCode,
      elt.companyName,
      elt.companyPhone,
      elt.bookingEmail,
      elt.paymentDate,
      elt.bookingType,
      elt.totalAmount,
      elt.paymentMode,
      elt.transactionId,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Payment_report.pdf")
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    const data = { searchQuery: e.target.value, fromDate: "", toDate: "" }
    axios
      .post(
        URLS.PaymentesReport,
        data,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.AllDetails)
        setuserInCsv(res.data.AllDetails)
      })
  }
  return (
    <div>
      <React.Fragment>
        {isLoading == true ? (
          <>
            <div
              style={{ zIndex: "9999999999999", height: "420px" }}
              className="text-center mt-5 pt-5"
            >
              <Spinner
                style={{ marginTop: "250px" }}
                className="ms-2"
                animation="border"
                color="primary"
                size="lg"
              />{" "}
              <div>Loading......</div>
            </div>
          </>
        ) : (
          <>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title="Kanavneer Admin"
                  breadcrumbItem="Payments Report"
                />

                {filter ? (
                  <>
                    <Card>
                      <CardBody>
                        <Form
                          onSubmit={e => {
                            getfilter(e)
                          }}
                        >
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-declaration-input10">
                                  From Date{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="date"
                                  required
                                  className="form-control"
                                  id="basicpill-Declaration-input10"
                                  onChange={e => {
                                    handleChangeflt(e)
                                  }}
                                  name="fromDate"
                                  value={filters.fromDate}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-declaration-input10">
                                  To Date <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="date"
                                  required
                                  className="form-control"
                                  id="basicpill-Declaration-input10"
                                  onChange={e => {
                                    handleChangeflt(e)
                                  }}
                                  name="toDate"
                                  value={filters.toDate}
                                />
                              </div>
                            </Col>

                            <Col lg="3">
                              <div className="mt-4">
                                <Button
                                  type="submit"
                                  className="m-1"
                                  color="primary"
                                >
                                  <i className="fas fa-check-circle"></i> search
                                </Button>
                                <Button
                                  onClick={hidefilter}
                                  className="m-1"
                                  color="danger"
                                >
                                  <i className="fas fa-times-circle"></i> Cancel
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </>
                ) : (
                  ""
                )}

                <Card>
                  <CardBody>
                    <div>
                      <div className="table-responsive">
                        <div className="col-sm-12">
                          <div style={{ float: "right" }}>
                            <Row>
                              <Col>
                                <div style={{ float: "right" }}>
                                  <CSVLink {...csvReport}>
                                    <button
                                      className="btn btn-success me-2"
                                      type="submit"
                                    >
                                      <i className="fas fa-file-excel"></i>{" "}
                                      Excel
                                    </button>
                                  </CSVLink>
                                  <Button
                                    type="button"
                                    className="btn btn-danger "
                                    onClick={exportPDF}
                                  >
                                    <i className="fas fa-file-pdf"></i> Pdf
                                  </Button>

                                  <Button
                                    className="m-1"
                                    onClick={() => {
                                      setfilter(!filter)
                                    }}
                                    color="primary"
                                  >
                                    <i className="fas fa-filter"></i> Filter
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className="col-sm-12 mt-2 mb-5">
                          <div style={{ float: "left" }}>
                            <Input
                              type="search"
                              className="form-control"
                              placeholder="Search.."
                              value={search.search}
                              onChange={searchAll}
                              name="search"
                            />
                          </div>
                        </div>

                        <div className="mt-5">
                          <Table
                            id="empTable"
                            className="table table-bordered mb-3 mt-5"
                          >
                            <thead className="text-center">
                              <tr>
                                <th>SlNo</th>
                                <th>Booking Date</th>
                                <th>Customer Code</th>
                                <th>Customer Name</th>
                                <th>Customer Phone</th>
                                <th>Customer Address</th>
                                <th>Customer PinCode</th>
                                <th>Franchise Id</th>
                                <th>Franchise Name</th>
                                <th>Franchise Phone</th>
                                <th>Franchise Email</th>
                                <th>Booking Type</th>
                                <th>Booking Amount</th>
                                <th>Payment Type</th>
                                <th>Transaction Id</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>
                                    <Moment format="DD-MM-YYYY">
                                      {data.purchaseDate}
                                    </Moment>
                                  </td>
                                  <td>{data.userCode}</td>
                                  <td>{data.customerName}</td>
                                  <td>{data.phone}</td>
                                  <td>{data.address}</td>
                                  <td>{data.pinCode}</td>
                                  <td>{data.franchiseCode}</td>
                                  <td>{data.companyName}</td>
                                  <td>{data.companyPhone}</td>
                                  <td>{data.bookingEmail}</td>
                                  <td>{data.bookingType}</td>
                                  <td>{data.totalAmount}</td>
                                  <td>{data.paymentMode}</td>
                                  <td>{data.transactionId}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </>
        )}
      </React.Fragment>
    </div>
  )
}

export default PaymentReport
