import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"
import Moment from "react-moment"

const ResponsiveTables = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [form1, setform1] = useState([])
  const [plans, setplans] = useState([])
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getPlans = () => {
    var token = datas
    axios
      .post(
        URLS.SubscriptionList,
        { bookingType: "Regular Plan" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.runningResult)
        setIsLoading(false)
      })
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas

    axios
      .post(
        URLS.SubscriptionListSearch + `${e.target.value}`,
        { bookingType: "Regular Plan" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.runningResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    getPlans()
  }, [])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const viewid = data => {
    history.push(
      "/ViewRegularSubscription",
      sessionStorage.setItem("Subids", data._id)
    )
  }

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "250px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs
                title="Kanavneer Admin"
                breadcrumbItem="Regular Plans Subscribers List"
              />
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={planssearch}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Customer Details </th>
                              <th>Franchise Details</th>
                              <th>Plan Type</th>
                              <th>Plan Duration</th>
                              <th>Amount</th>
                              <th>Purchasing Date</th>
                              <th>Expire Date </th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                <td>
                                  {data.customerName}/{data.customerPhone}
                                </td>
                                <td>
                                  {data.franchiseName}/{data.franchisePhone}
                                </td>
                                <td>{data.regularPlanType}</td>
                                <td>{data.planPeriod} Months</td>
                                <td>{data.totalPrice}</td>
                                <td>
                                  <Moment format="DD-MM-YYYY">
                                    {data.purchaseDate}
                                  </Moment>
                                </td>
                                <td>
                                  <Moment format="DD-MM-YYYY">
                                    {data.expiryDate}
                                  </Moment>
                                </td>
                                <td>
                                  <span className="badge bg-success">
                                    {data.runningStatus}
                                  </span>
                                </td>
                                <td style={{ width: "200px" }}>
                                  <Button
                                    onClick={() => {
                                      viewid(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="secondary"
                                  >
                                    <div className="d-flex">
                                      <i className="fas fa-eye"></i>{" "}
                                      <small>View</small>
                                    </div>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <ToastContainer />
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default ResponsiveTables
