import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Label,
  Form,
  CardBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import { URLS } from "../../Url"

const Addplan = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [inputList, setInputList] = useState([{ info: "", advantage: "" }])

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { info: "", advantage: "" }])
  }

  const [form, setform] = useState([])
  const [Files, setFiles] = useState("")

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  let history = useHistory()

  const handleChange = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const Addplans = () => {
    const token = datas
    const dataArray = new FormData()
    dataArray.append("planType", form.planType)

    dataArray.append("oneMonthPrice", parseFloat(form.oneMonthPrice))
    dataArray.append("oneMonthBonusDays", parseFloat(form.oneMonthBonusDays))
    dataArray.append("oneMonthBonusLitrs", parseFloat(form.oneMonthBonusLitrs))
    dataArray.append("oneMonthTotalLitrs", parseFloat(form.oneMonthTotalLitrs))
    dataArray.append("threeMonthPrice", parseFloat(form.threeMonthPrice))

    dataArray.append(
      "threeMonthBonusDays",
      parseFloat(form.threeMonthBonusDays)
    )
    dataArray.append(
      "threeMonthBonusLitrs",
      parseFloat(form.threeMonthBonusLitrs)
    )
    dataArray.append(
      "threeMonthTotalLitrs",
      parseFloat(form.threeMonthTotalLitrs)
    )

    dataArray.append("sixMonthPrice", parseFloat(form.sixMonthPrice))
    dataArray.append("sixMonthBonusDays", parseFloat(form.sixMonthBonusDays))
    dataArray.append("sixMonthBonusLitrs", parseFloat(form.sixMonthBonusLitrs))
    dataArray.append("sixMonthTotalLitrs", parseFloat(form.sixMonthTotalLitrs))

    dataArray.append("twelveMonthPrice", parseFloat(form.twelveMonthPrice))
    dataArray.append(
      "twelveMonthBonusDays",
      parseFloat(form.twelveMonthBonusDays)
    )
    dataArray.append(
      "twelveMonthBonusLitrs",
      parseFloat(form.twelveMonthBonusLitrs)
    )
    dataArray.append(
      "twelveMonthTotalLitrs",
      parseFloat(form.twelveMonthTotalLitrs)
    )

    dataArray.append("description", form.description)
    dataArray.append("planAdvantages", JSON.stringify(inputList))
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("planImage", Files[i])
    }

    axios
      .post(URLS.AddAddRegularPlan, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setform("")
            setFiles("")
            history.push(
              "/RegularPlans",
              sessionStorage.setItem(
                "tost",
                "Regular Plan has been Added successfully"
              )
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formsubmit = e => {
    e.preventDefault()
    Addplans()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Kanavneer Admin"
            breadcrumbItem="Add Regular Plan"
          />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form
                onSubmit={e => {
                  formsubmit(e)
                }}
              >
                <Card>
                  <CardBody>
                    <h5
                      className="text-primary mb-3"
                      style={{ textDecoration: "underline" }}
                    >
                      Regular PLAN
                    </h5>
                    <Row>
                      <Col md="4" className="mt-2">
                        <Label>Plan Type</Label>
                        <span className="text-danger">*</span>
                        <select
                          onChange={e => {
                            handleChange(e)
                          }}
                          value={form.planType}
                          name="planType"
                          className="form-select"
                          required
                        >
                          <option value="">Select Type</option>
                          <option value="Duo">Duo </option>
                          <option value="Quad">Quad</option>
                          <option value="Hexa">Hexa</option>
                        </select>
                      </Col>
                      <Col md="4" className="mt-2">
                        <Label>Image</Label>{" "}
                        <span className="text-danger">* 195*260</span>
                        <Input
                          type="file"
                          className="form-control"
                          required
                          name="planImage"
                          value={Files.planImage}
                          onChange={changeHandler1}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <h5
                        className="text-primary mb-3"
                        style={{ textDecoration: "underline" }}
                      >
                        One Month Plan :-
                      </h5>

                      <Col md="3" className="mb-3">
                        <Label> Recharge</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="oneMonthPrice"
                          required
                          value={form.oneMonthPrice}
                          type="number"
                          placeholder="Enter price"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label> Bonus Days</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="oneMonthBonusDays"
                          required
                          value={form.oneMonthBonusDays}
                          type="number"
                          placeholder="Enter Bonus Days"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label> Bonus Liters</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="oneMonthBonusLitrs"
                          required
                          value={form.oneMonthBonusLitrs}
                          type="number"
                          placeholder="Enter Bonus Liters"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label>Total Liters</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="oneMonthTotalLitrs"
                          required
                          value={form.oneMonthTotalLitrs}
                          type="number"
                          placeholder="Enter Total Liters"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <h5
                        className="text-primary mb-3"
                        style={{ textDecoration: "underline" }}
                      >
                        Three Months Plan :-
                      </h5>

                      <Col md="3" className="mb-3">
                        <Label> Recharge</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="threeMonthPrice"
                          required
                          value={form.threeMonthPrice}
                          type="number"
                          placeholder="Enter price"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label> Bonus Days</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="threeMonthBonusDays"
                          required
                          value={form.threeMonthBonusDays}
                          type="number"
                          placeholder="Enter Bonus Days"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label> Bonus Liters</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="threeMonthBonusLitrs"
                          required
                          value={form.threeMonthBonusLitrs}
                          type="number"
                          placeholder="Enter Bonus Liters"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label>Total Liters</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="threeMonthTotalLitrs"
                          required
                          value={form.threeMonthTotalLitrs}
                          type="number"
                          placeholder="Enter Total Liters"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <h5
                        className="text-primary mb-3"
                        style={{ textDecoration: "underline" }}
                      >
                        Six Months Plan :-
                      </h5>
                      <Col md="3" className="mb-3">
                        <Label> Recharge</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="sixMonthPrice"
                          required
                          value={form.sixMonthPrice}
                          type="number"
                          placeholder="Enter price"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label> Bonus Days</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="sixMonthBonusDays"
                          required
                          value={form.sixMonthBonusDays}
                          type="number"
                          placeholder="Enter Bonus Days"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label> Bonus Liters</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="sixMonthBonusLitrs"
                          required
                          value={form.sixMonthBonusLitrs}
                          type="number"
                          placeholder="Enter Bonus Liters"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label>Total Liters</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="sixMonthTotalLitrs"
                          required
                          value={form.sixMonthTotalLitrs}
                          type="number"
                          placeholder="Enter Total Liters"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <h5
                        className="text-primary mb-3"
                        style={{ textDecoration: "underline" }}
                      >
                        Twelve Months Plan:-
                      </h5>

                      <Col md="3" className="mb-3">
                        <Label> Recharge</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="twelveMonthPrice"
                          required
                          value={form.twelveMonthPrice}
                          type="number"
                          placeholder="Enter price"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label> Bonus Days</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="twelveMonthBonusDays"
                          required
                          value={form.twelveMonthBonusDays}
                          type="number"
                          placeholder="Enter Bonus Days"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label> Bonus Liters</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="twelveMonthBonusLitrs"
                          required
                          value={form.twelveMonthBonusLitrs}
                          type="number"
                          placeholder="Enter Bonus Liters"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label>Total Liters</Label>
                        <span className="text-danger">*</span>
                        <Input
                          onChange={e => {
                            handleChange(e)
                          }}
                          name="twelveMonthTotalLitrs"
                          required
                          value={form.twelveMonthTotalLitrs}
                          type="number"
                          placeholder="Enter Total Liters"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h5
                      className="text-primary mb-3"
                      style={{ textDecoration: "underline" }}
                    >
                      Plan Information :-
                    </h5>
                    <Col md={12}>
                      <Label>Plan Description</Label>
                      <span className="text-danger">*</span>
                      <textarea
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="description"
                        required
                        rows={3}
                        value={form.description}
                        type="text"
                        placeholder="Enter Plan Description"
                        className="form-control"
                      />
                    </Col>

                    <Row className="mb-5">
                      <div className="mt-4">
                        <Label>Plan Info / Advantages</Label>
                        <Row>
                          {inputList.map((x, i) => {
                            return (
                              <>
                                <Row>
                                  <div key={i} className="box row">
                                    <Col md="5" sm="12" className="mb-1">
                                      <Input
                                        type="text"
                                        name="info"
                                        placeholder="Enter Plan Info"
                                        value={x.info}
                                        onChange={e => handleInputChange(e, i)}
                                      />
                                    </Col>

                                    <Col md="5" sm="12" className="mb-1">
                                      <Input
                                        type="text"
                                        name="advantage"
                                        placeholder="Enter Plan Advantage"
                                        value={x.advantage}
                                        onChange={e => handleInputChange(e, i)}
                                      />
                                    </Col>

                                    <Col sm="2">
                                      <div className="btn-box">
                                        {inputList.length !== 1 && (
                                          <button
                                            className="mr10 btn btn-outline-danger btn-sm m-1"
                                            type="button"
                                            onClick={() => handleRemoveClick(i)}
                                          >
                                            Remove
                                            <i className="bx bx-x-circle"></i>
                                          </button>
                                        )}
                                        {inputList.length - 1 === i && (
                                          <button
                                            className="btn btn-sm btn-outline-info m-1"
                                            onClick={handleAddClick}
                                          >
                                            Add More
                                            <i className="bx bx-plus-circle"></i>
                                          </button>
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                </Row>
                              </>
                            )
                          })}
                        </Row>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <div className="text-end">
                  <Button type="submit" color="success m-1 mb-4">
                    Submit <i className="bx bx-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Addplan
