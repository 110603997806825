import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "reactstrap"
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { toast } from "react-toastify"
import { URLS } from "../../Url"

function TermsConditions() {
  const [form, setform] = useState([])

  useEffect(() => {
    GetSettings()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetSettings = () => {
    var token = datas
    const dataArray = new FormData()

    axios
      .post(URLS.GetServiceManSetting, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.setting.termsAndCondition)
      })
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const [text1, setText1] = useState([])

  const getpopup1 = forms => {
    setText1(forms)
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }

  const changstatus = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("termsAndCondition", text1)

    axios
      .put(URLS.UpdateServiceMantermsAndCondition, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetSettings()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader className="bg-white"></CardHeader>

            <CardBody>
              <div style={{ float: "right" }}>
                <Button
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Edit Booking"
                  onClick={() => {
                    getpopup1(form)
                  }}
                  className="mr-5"
                  color="info"
                >
                  <i className="bx bx-edit text-white "></i>
                  <span>Edit</span>
                </Button>
              </div>
              <div>
                <div>
                  <h5>Service Man Terms & Conditions</h5>
                  <div
                    className="mt-5"
                    dangerouslySetInnerHTML={{
                      __html: form,
                    }}
                  ></div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        size="lg"
        isOpen={modal_small}
        toggle={() => {
          tog_small()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Terms & Conditions Edit
          </h5>
          <button
            onClick={() => {
              setmodal_small(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Form
          onSubmit={e => {
            submibooking(e)
          }}
        >
          <div className="modal-body">
            <CKEditor
              editor={ClassicEditor}
              id="header"
              data={text1}
              onReady={editor => {
                console.log("Editor is ready to use!", editor)
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                setText1(data)
              }}
            />
          </div>

          <hr></hr>
          <div style={{ float: "right" }} className="m-2">
            <Button className="m-1" color="primary" type="submit">
              Submit <i className="fas fa-check-circle"></i>
            </Button>
            <Button
              onClick={() => {
                setmodal_small(false)
              }}
              color="danger"
              type="button"
            >
              Cancel <i className="fas fa-times-circle"></i>
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default TermsConditions
