import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../Url"

const WebBanner = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [banner, setbanner] = useState([])
  const [form1, setform1] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [files, setFiles] = useState({
    aboutusImage: "",
    allPlansImage: "",
    howItWorksImage: "",
    contactusImage: "",
  })

  const changeHandler1 = e => {
    const { name, files: fileList } = e.target
    const file = fileList[0] // Get the first selected file
    if (file) {
      const ext = file.name.split(".").pop().toLowerCase() // Get file extension in lowercase

      if (ext === "jpg" || ext === "jpeg" || ext === "png") {
        // Set the file for the correct field
        setFiles(prevFiles => ({
          ...prevFiles,
          [name]: file,
        }))
      } else {
        e.target.value = null // Reset the file input
        toast(
          "File format not supported. Please choose an image (jpg, jpeg, or png)"
        )
      }
    }
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const EditBanner = () => {
    var token = datas
    const dataArray = new FormData()

    // Append each file to the corresponding key
    if (files.aboutusImage) {
      dataArray.append("aboutusImage", files.aboutusImage)
    }

    if (files.allPlansImage) {
      dataArray.append("allPlansImage", files.allPlansImage)
    }

    if (files.howItWorksImage) {
      dataArray.append("howItWorksImage", files.howItWorksImage)
    }

    if (files.contactusImage) {
      dataArray.append("contactusImage", files.contactusImage)
    }
    axios
      .put(URLS.WebupdateBanners, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            // clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.WebBanners,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.webbanner)
        setIsLoading(false)
      })
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Kanavneer Admin"
            breadcrumbItem="Website Banners"
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Website Banners</CardTitle>
                </CardHeader>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <Spinner
                        style={{ marginTop: "250px" }}
                        className="ms-2"
                        animation="border"
                        color="primary"
                        size="lg"
                      />{" "}
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <form onSubmit={handleSubmit1}>
                        <Row>
                          <Col md="3">
                            <div>
                              <div className="row">
                                <div className="col-6">
                                  <label>About Us Image:</label>
                                </div>
                                <div
                                  onClick={() => {
                                    getpopup(banner.aboutusImage)
                                  }}
                                  className="col-6 text-end"
                                >
                                  <i className="fa fa-eye text-danger" />
                                </div>
                              </div>
                              <input
                                type="file"
                                className="form-control"
                                name="aboutusImage"
                                accept="image/*"
                                onChange={changeHandler1}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div>
                              <div className="row">
                                <div className="col-6">
                                  <label>All Plans Image:</label>
                                </div>
                                <div
                                  onClick={() => {
                                    getpopup(banner.aboutusImage)
                                  }}
                                  className="col-6 text-end"
                                >
                                  <i className="fa fa-eye text-danger" />
                                </div>
                              </div>

                              <input
                                type="file"
                                className="form-control"
                                name="allPlansImage"
                                accept="image/*"
                                onChange={changeHandler1}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div>
                              <div className="row">
                                <div className="col-10">
                                  <label>How It Works Image:</label>
                                </div>
                                <div
                                  onClick={() => {
                                    getpopup(banner.aboutusImage)
                                  }}
                                  className="col-2 text-end"
                                >
                                  <i className="fa fa-eye text-danger" />
                                </div>
                              </div>

                              <input
                                className="form-control"
                                type="file"
                                name="howItWorksImage"
                                accept="image/*"
                                onChange={changeHandler1}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div>
                              <div className="row">
                                <div className="col-10">
                                  <label>Contact Us Image:</label>
                                </div>
                                <div
                                  onClick={() => {
                                    getpopup(banner.aboutusImage)
                                  }}
                                  className="col-2 text-end"
                                >
                                  <i className="fa fa-eye text-danger" />
                                </div>
                              </div>

                              <input
                                className="form-control"
                                type="file"
                                name="contactusImage"
                                accept="image/*"
                                onChange={changeHandler1}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="text-end mt-3">
                          <Button color="success">Submit</Button>
                        </div>
                      </form>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Banner View
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              src={"https://api.kanavneer.com/" + form1}
              style={{ width: "100%" }}
            />
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default WebBanner
