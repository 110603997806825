import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  Table,
  Form,
  Label,
  Modal,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../Url"
import axios from "axios"
import Moment from "react-moment"

function Complaintbox() {
  const [modal_small, setmodal_small] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [form1, setform1] = useState([])
  const [plans, setplans] = useState([])
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetCancelledServices,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.complaintResult)
        setIsLoading(false)
      })
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas

    axios
      .post(
        URLS.GetCancelledServicesSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.complaintResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    getPlans()
  }, [])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [ServiceMan, setServiceMan] = useState([])

  useEffect(() => {
    getServiceMan()
  }, [])

  const getServiceMan = () => {
    var token = datas
    axios
      .post(
        URLS.GetServiceman,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setServiceMan(res.data.serviceResult)
      })
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const EditBanner = () => {
    var token = datas

    const dataArray = {
      complainId: form1._id,
      servicemanId: form1.servicemanId,
    }

    axios
      .put(URLS.AssignServicemanService, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }
  const history = useHistory()

  const viewid = data => {
    history.push(
      "/ViewComplaintbox",
      sessionStorage.setItem("ServiceId", data._id)
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading == true ? (
          <>
            <div
              style={{ zIndex: "9999999999999", height: "420px" }}
              className="text-center mt-5 pt-5"
            >
              <Spinner
                style={{ marginTop: "250px" }}
                className="ms-2"
                animation="border"
                color="primary"
                size="lg"
              />{" "}
              <div>Loading......</div>
            </div>
          </>
        ) : (
          <>
            <Container fluid>
              <Breadcrumbs
                title="Kanavneer Admin"
                breadcrumbItem="Cancelled Services"
              />
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <div>
                        <div className="table-responsive">
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={planssearch}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Ticket Id</th>
                                <th>Date</th>
                                <th>Franchise Name / Phone</th>
                                <th>Customer Name</th>
                                <th>Phone</th>
                                <th>Request Type</th>
                                <th>Description</th>
                                <th>Service Man Details</th>
                                <th>Cancelled Reason</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>{data.ticketId}</td>
                                  <td>
                                    <Moment format="DD-MM-YYYY">
                                      {data.logCreatedDate}
                                    </Moment>{" "}
                                  </td>
                                  <td>
                                    {data.franchiseName}/{data.franchisePhone}
                                  </td>
                                  <td>{data.userName}</td>
                                  <td>{data.userPhone}</td>
                                  <td>{data.requestType} </td>
                                  <td>{data.description}</td>
                                  <td>
                                    {data.serviceManName}-{" "}
                                    {data.serviceManPhone}
                                  </td>
                                  <td>{data.cancelledReason}</td>
                                  <td>
                                    {" "}
                                    <span className="badge bg-warning">
                                      {data.status}
                                    </span>
                                  </td>
                                  <td style={{ width: "200px" }}>
                                    {" "}
                                    <Button
                                      onClick={() => {
                                        viewid(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      color="secondary"
                                    >
                                      <div className="d-flex">
                                        <i className="fas fa-eye"></i>
                                        <small>View</small>
                                      </div>
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        getpopup(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      style={{
                                        background: "#FF8C00",
                                        border: "#FF8C00",
                                      }}
                                    >
                                      <div className="d-flex">
                                        <i className="bx bxs-user-detail"></i>
                                        <small>Re-assigning</small>
                                      </div>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <Modal
              size="md"
              isOpen={modal_small}
              toggle={() => {
                tog_small()
              }}
              centered
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="mySmallModalLabel">
                  Re-assigning Service Man
                </h5>
                <button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Form
                  onSubmit={e => {
                    handleSubmit1(e)
                  }}
                >
                  <Col md={12}>
                    <div className="mb-3">
                      <Label> Service Man</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form1.servicemanId}
                        name="servicemanId"
                        onChange={e => {
                          handleChange1(e)
                        }}
                        className="form-select"
                        required
                      >
                        <option value="">Select</option>
                        {ServiceMan.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.firstName}
                              {data.lastName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <div style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        setmodal_small(false)
                      }}
                      color="danger"
                      type="button"
                    >
                      Cancel <i className="fas fa-times-circle"></i>
                    </Button>
                    <Button className="m-1" color="primary" type="submit">
                      Submit <i className="fas fa-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal>
          </>
        )}
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Complaintbox
