import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Table,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"

function TermsConditions() {
  const [form, setform] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetSettings = () => {
    var token = datas
    const dataArray = new FormData()

    axios
      .post(URLS.GetSetting, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.settingResult.termsAndCondition)
      })
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const [text1, setText1] = useState([])

  const getpopup1 = forms => {
    setText1(forms)
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }

  const changstatus = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("termsAndCondition", text1)

    axios
      .put(URLS.UpdateSettings, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetSettings()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kanavneer Admin" breadcrumbItem="Sms Getway" />
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white"></CardHeader>

                <CardBody>
                  <div style={{ float: "right" }}>
                    <Button
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Edit Booking"
                      onClick={() => {
                        getpopup1(form)
                      }}
                      className="mr-5"
                      color="success"
                      outline
                    >
                      <i className="bx bx-edit text-white "></i>
                      <span>Edit</span>
                    </Button>
                  </div>
                  <div>
                    <div>
                      <h5>Sms Getway</h5>
                      <Table hover className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr className="text-center">
                            <th>Otp Key</th>
                            <th>Success Key</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center">
                            <td>Otp@Key</td>
                            <td> Success@Key</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Payment Getway
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <Form
              onSubmit={e => {
                submibooking(e)
              }}
            >
              <div className="modal-body">
                <Label>Solve Key</Label> <span className="text-danger">*</span>
                <Input
                  onChange={e => {
                    handleChange(e)
                  }}
                  name="Solve Key"
                  required
                  pattern="[a-zA-Z0-9\s.,$@%*]+"
                  type="text"
                  placeholder="Enter Solve Key"
                />
              </div>
              <div className="modal-body">
                <Label>Merchant Api Key</Label>{" "}
                <span className="text-danger">*</span>
                <Input
                  onChange={e => {
                    handleChange(e)
                  }}
                  name="Merchant Api Key"
                  required
                  pattern="[a-zA-Z0-9\s.,$@%*]+"
                  type="text"
                  placeholder="Enter Merchant Api Key"
                />
              </div>
              <hr></hr>
              <div style={{ float: "right" }} className="m-2">
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
              </div>
            </Form>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default TermsConditions
