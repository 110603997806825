import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Input,
  Table,
  Button,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../Url"
import { useHistory } from "react-router-dom"
import Moment from "react-moment"

function Complaintbox() {
  const [form1, setform1] = useState([])
  const [plans, setplans] = useState([])
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [isLoading, setIsLoading] = useState(true)

  const getPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetInProgressCompleteBox,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.complaintResult)
        setIsLoading(false)
      })
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas

    axios
      .post(
        URLS.GetInProgressCompleteBoxSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.complaintResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    getPlans()
  }, [])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const history = useHistory()

  const viewid = data => {
    history.push(
      "/ViewComplaintbox",
      sessionStorage.setItem("ServiceId", data._id)
    )
  }

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "250px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title="Kanavneer Admin"
                breadcrumbItem="InProgress Services "
              />
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <div>
                        <div className="table-responsive">
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={planssearch}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Ticket Id</th>
                                <th>Date</th>
                                <th>Franchise Name / Phone</th>
                                <th>Customer Name</th>
                                <th>Phone</th>
                                <th>ServiceMan Name / Phone</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>{data.ticketId}</td>
                                  <td>
                                    <Moment format="DD-MM-YYYY">
                                      {data.logCreatedDate}
                                    </Moment>{" "}
                                  </td>
                                  <td>
                                    {data.franchiseName}/{data.franchisePhone}
                                  </td>
                                  <td>{data.userName}</td>
                                  <td>{data.userPhone}</td>
                                  <td>
                                    {" "}
                                    {data.serviceManName} -
                                    {data.serviceManPhone}
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="badge bg-success">
                                      {data.status}
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    <Button
                                      onClick={() => {
                                        viewid(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      color="secondary"
                                    >
                                      <div className="d-flex">
                                        <i className="fas fa-eye"></i>{" "}
                                        <small>View</small>
                                      </div>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <ToastContainer />
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default Complaintbox
