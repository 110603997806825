import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { URLS } from "Url"
import { useHistory } from "react-router-dom"
import classnames from "classnames"
import StarRatings from "react-star-ratings"
import Moment from "react-moment"

function AddVendors() {
  const [Customer, setCustomer] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [Franchise, setFranchise] = useState([])

  const [Payment, setPayment] = useState([])

  const [result, setresult] = useState([])

  const [ServiceMan, setServiceMan] = useState([])

  const [ServiceMantrack, setServiceMantrack] = useState([])

  const [Services, setServices] = useState([])

  const [Machine, setMachine] = useState([])

  const [ServiceManRating, setServiceManRating] = useState([])

  const [activeTab1, setactiveTab1] = useState("1")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getPlansid()
  }, [])

  const plansid = sessionStorage.getItem("RegisterId")

  const getPlansid = () => {
    const dataArray = {
      id: plansid,
    }
    var token = datas
    axios
      .post(URLS.ViewBookingId, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setIsLoading(false)
        setMachine(res.data?.result[0]?.otherDetails)
        setCustomer(res.data?.result[0]?.customer[0])
        setFranchise(res.data?.result[0]?.franchise[0])
        setPayment(res.data?.result[0]?.payment[0])
        setresult(res.data?.result[0])
        const data = res.data?.result[0]?.serviceman
        if (data.length == 1) {
          setServiceMan(res.data?.result[0]?.serviceman[0])
        } else {
          setServiceMan(res.data?.result[0]?.serviceman)
        }
        setServiceMantrack(res.data?.result[0]?.trackTimings)
        setServices(res.data?.result[0]?.serviceTrack)
        setServiceManRating(res.data?.result[0]?.servicemanratings)
      })
  }

  const history = useHistory()

  const rates = Number(ServiceManRating?.rating ?? 0)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kanavneer Admin" breadcrumbItem="View Bookings" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <Spinner
                  style={{ marginTop: "20px" }}
                  className="ms-2"
                  animation="border"
                  color="primary"
                  size="lg"
                />{" "}
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "5px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "1",
                            })}
                            onClick={() => {
                              toggle1("1")
                            }}
                          >
                            Customer Details / Franchise Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "5px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "2",
                            })}
                            onClick={() => {
                              toggle1("2")
                            }}
                          >
                            Booking Details / Plans Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "5px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "3",
                            })}
                            onClick={() => {
                              toggle1("3")
                            }}
                          >
                            Service Man Details / Timing Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <TabContent
                    activeTab={activeTab1}
                    className="pt-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row className="mb-4 mt-4">
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary">
                            Customer Profile:
                          </h5>
                          <ul className="list-unstyled vstack gap-3 mb-0">
                            <a
                              href={URLS.Base + Customer?.profilePic}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={URLS.Base + Customer?.profilePic}
                                width="120px"
                                height="120px"
                              ></img>
                            </a>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Name:{" "}
                                  </h6>
                                  <span className="text-muted">
                                    {Customer?.customerName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Email:
                                  </h6>
                                  <span className="text-muted">
                                    {Customer?.email}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Phone:
                                  </h6>
                                  {Customer?.phone}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Address :
                                  </h6>
                                  {Customer?.address}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    PinCode :
                                  </h6>
                                  {Customer?.pinCode}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Status :</h6>
                                  <span className="badge bg-warning">
                                    {Customer?.status}
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary">
                            Franchise Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Id
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.franchiseCode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Name
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.companyName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Mobile
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.companyPhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Email
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">State</h6>
                                    <p className="text-muted">
                                      {Franchise?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      District
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.districtName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">City</h6>
                                    <p className="text-muted">
                                      {Franchise?.cityName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Address
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="mb-4">
                        <Col lg={6} className="mb-4">
                          <h5 className="mb-4 text-primary mt-5">
                            Booking Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Type
                                    </h6>
                                    <p className="text-muted">
                                      {result?.bookingType}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Date{" "}
                                    </h6>
                                    <p className="text-muted">
                                      <Moment format="DD-MM-YYYY">
                                        {result?.purchaseDate}
                                      </Moment>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Amount
                                    </h6>
                                    <p className="text-muted">
                                      {result?.totalPrice}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={6} className="mb-4">
                          <h5 className="mb-4 text-primary mt-5">
                            Payment Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Payment Date
                                    </h6>
                                    <p className="text-muted">
                                      <Moment format="DD-MM-YYYY">
                                        {Payment?.paymentDate}
                                      </Moment>{" "}
                                      / {Payment?.paymentTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Payment Type
                                    </h6>
                                    <p className="text-muted">
                                      {Payment?.paymentMode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Transaction Id
                                    </h6>
                                    <p className="text-muted">
                                      {Payment?.transactionId}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <hr></hr>
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary mt-5">
                            Machine Details :
                          </h5>

                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Mac Id
                                    </h6>
                                    <p className="text-muted">
                                      {Customer?.macAddress}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Floor Height
                                    </h6>
                                    <p className="text-muted">
                                      {Machine?.floor}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Tds Value
                                    </h6>
                                    <p className="text-muted">
                                      {Machine?.tdsValue}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Water Type
                                    </h6>
                                    <p className="text-muted">
                                      {Machine?.waterType}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col lg={6} className="mb-2">
                          <h5 className="mb-4 text-primary mt-5">
                            Service Man Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Man Name :
                                    </h6>
                                    <p className="text-muted">
                                      {ServiceMan?.firstName}{" "}
                                      {ServiceMan?.lastName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Man Phone
                                    </h6>
                                    <p className="text-muted">
                                      {ServiceMan?.phone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Man Email
                                    </h6>
                                    <p className="text-muted">
                                      {ServiceMan?.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Man Address
                                    </h6>
                                    <p className="text-muted">
                                      {ServiceMan?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={6} className="mb-2">
                          <h5 className="mb-4 text-primary mt-5">
                            Booking Track / Timing :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Assigned / Timing:
                                    </h6>
                                    <p className="text-muted">
                                      {Services?.bookingAccepted == "active" ? (
                                        <span className="text-success">
                                          Completed{" "}
                                        </span>
                                      ) : (
                                        <span className="text-warning">
                                          Pending{" "}
                                        </span>
                                      )}{" "}
                                      /
                                      {ServiceMantrack?.bookingAcceptedDate ==
                                        null ||
                                      ServiceMantrack?.bookingAcceptedDate ==
                                        "" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <Moment format="DD-MM-YYYY">
                                            {
                                              ServiceMantrack?.bookingAcceptedDate
                                            }
                                          </Moment>
                                        </>
                                      )}
                                      - {ServiceMantrack?.bookingAcceptedTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Machine Dispatched / Timing
                                    </h6>
                                    <p className="text-muted">
                                      {" "}
                                      {Services?.machineDispatched ==
                                      "active" ? (
                                        <span className="text-success">
                                          Completed{" "}
                                        </span>
                                      ) : (
                                        <span className="text-warning">
                                          Pending{" "}
                                        </span>
                                      )}{" "}
                                      /{" "}
                                      {ServiceMantrack?.dispatchDate == null ||
                                      ServiceMantrack?.dispatchDate == "" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <Moment format="DD-MM-YYYY">
                                            {ServiceMantrack?.dispatchDate}
                                          </Moment>
                                        </>
                                      )}
                                      - {ServiceMantrack?.dispatchTime}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Started / Timing
                                    </h6>
                                    <p className="text-muted">
                                      {" "}
                                      {Services?.serviceStarted == "active" ? (
                                        <span className="text-success">
                                          Completed{" "}
                                        </span>
                                      ) : (
                                        <span className="text-warning">
                                          Pending{" "}
                                        </span>
                                      )}{" "}
                                      /
                                      {ServiceMantrack?.serviceStartDate ==
                                        null ||
                                      ServiceMantrack?.serviceStartDate ==
                                        "" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <Moment format="DD-MM-YYYY">
                                            {ServiceMantrack?.serviceStartDate}
                                          </Moment>
                                        </>
                                      )}
                                      - {ServiceMantrack?.serviceStartTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service End / Timing
                                    </h6>
                                    <p className="text-muted">
                                      {Services?.serviceEnd == "active" ? (
                                        <span className="text-success">
                                          Completed{" "}
                                        </span>
                                      ) : (
                                        <span className="text-warning">
                                          Pending{" "}
                                        </span>
                                      )}{" "}
                                      /
                                      {ServiceMantrack?.serviceEndDate ==
                                        null ||
                                      ServiceMantrack?.serviceEndDate == "" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <Moment format="DD-MM-YYYY">
                                            {ServiceMantrack?.serviceEndDate}
                                          </Moment>{" "}
                                        </>
                                      )}
                                      - {ServiceMantrack?.serviceEndTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <hr></hr>
                        <Col lg={6} className="pt-3">
                          <h5 className="text-primary mb-3">
                            Reviews / Rating :-
                          </h5>
                          <Row className="mt-2">
                            <Col md={12}>
                              <div className="d-flex mt-4">
                                <img
                                  src={URLS.Base + Customer?.profilePic}
                                  className="avatar-md me-3 rounded-circle"
                                  alt="img"
                                />
                                <div className="flex-grow-1">
                                  <h5 className="mt-0 mb-1 font-size-15">
                                    {Customer?.customerName} :{" "}
                                    <span className="text-primary">
                                      (Rating :
                                      <StarRatings
                                        rating={rates}
                                        starRatedColor="#F1B44C"
                                        starEmptyColor="#2D363F"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="3px"
                                      />
                                      )
                                    </span>
                                  </h5>
                                  <p className="text-muted pt-2 pb-2">
                                    {ServiceManRating?.description}
                                  </p>
                                  <div className="text-muted font-size-12">
                                    <i className="far fa-calendar-alt text-primary me-1" />
                                    <Moment format="DD-MM-YYYY">
                                      {ServiceManRating?.logCreatedDate}
                                    </Moment>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
