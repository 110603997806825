import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Table,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { URLS } from "Url"
import { useHistory } from "react-router-dom"
import classnames from "classnames"
import ReactPaginate from "react-paginate"
import Moment from "react-moment"

function AddVendors() {
  const [form, setform] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [activeTab1, setactiveTab1] = useState("1")

  const [Franchise, setFranchise] = useState([])

  const [Payment, setPayment] = useState([])

  const [result, setresult] = useState([])

  const [ServiceMan, setServiceMan] = useState([])

  const [ServiceMantrack, setServiceMantrack] = useState([])

  const [Services, setServices] = useState([])

  const [PendingServices, setPendingServices] = useState([])

  const [CompletedServices, setCompletedServices] = useState([])

  const [Plan, setPlan] = useState([])

  const [Machine, setMachine] = useState([])

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getPlansid()
  }, [])

  const plansid = sessionStorage.getItem("CustId")

  const getPlansid = () => {
    const dataArray = new FormData()
    dataArray.append("_id", plansid)

    var token = datas
    axios
      .post(URLS.getCustomersid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setIsLoading(false)
        setServiceMantrack(res?.data?.bookingDetails[0]?.trackTimings)
        setServiceMan(res?.data?.bookingDetails[0]?.serviceman[0])
        setFranchise(res?.data?.bookingDetails[0]?.franchise[0])
        setServices(res?.data?.bookingDetails[0]?.serviceTrack)
        setMachine(res?.data?.bookingDetails[0]?.otherDetails)
        setCompletedServices(res?.data?.completedComplaints)
        setPayment(res?.data?.bookingDetails[0]?.payment[0])
        setPendingServices(res?.data?.pendingComplaints)
        setresult(res?.data?.bookingDetails[0])
        setform(res?.data?.customerResult[0])
        setPlan(res?.data?.AllDetails)
      })
  }

  const history = useHistory()

  const [listPerPage2] = useState(20)
  const [pageNumber2, setPageNumber2] = useState(0)

  const pagesVisited2 = pageNumber2 * listPerPage2
  const lists2 = PendingServices.slice(
    pagesVisited2,
    pagesVisited2 + listPerPage2
  )
  const pageCount2 = Math.ceil(PendingServices.length / listPerPage2)
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected)
  }

  const [listPerPage3] = useState(20)
  const [pageNumber3, setPageNumber3] = useState(0)

  const pagesVisited3 = pageNumber3 * listPerPage3
  const lists3 = CompletedServices.slice(
    pagesVisited3,
    pagesVisited3 + listPerPage3
  )
  const pageCount3 = Math.ceil(CompletedServices.length / listPerPage3)
  const changePage3 = ({ selected }) => {
    setPageNumber3(selected)
  }

  const viewid1 = data => {
    history.push(
      "/ViewComplaintbox",
      sessionStorage.setItem("ServiceId", data._id)
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kanavneer Admin" breadcrumbItem="View Customer" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <Spinner
                  style={{ marginTop: "20px" }}
                  className="ms-2"
                  animation="border"
                  color="primary"
                  size="lg"
                />{" "}
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "1",
                            })}
                            onClick={() => {
                              toggle1("1")
                            }}
                          >
                            Customer Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "2",
                            })}
                            onClick={() => {
                              toggle1("2")
                            }}
                          >
                            Booking Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "3",
                            })}
                            onClick={() => {
                              toggle1("3")
                            }}
                          >
                            Pending Services
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "4",
                            })}
                            onClick={() => {
                              toggle1("4")
                            }}
                          >
                            Completed Services
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            Plan Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <TabContent
                    activeTab={activeTab1}
                    className="pt-3 text-muted mt-4"
                  >
                    <TabPane tabId="1">
                      <Row className="mb-4">
                        <Col lg={4}>
                          <h5 className="mb-4 text-primary">
                            Customer Profile:
                          </h5>
                          <ul className="list-unstyled vstack gap-3 mb-0">
                            <a
                              href={URLS.Base + form?.profilePic}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={URLS.Base + form?.profilePic}
                                width="120px"
                                height="120px"
                              ></img>
                            </a>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-address-book font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Name:
                                  </h6>
                                  <span className="text-muted">
                                    {form?.customerName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-address-book font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer User Code:
                                  </h6>
                                  <span className="text-muted">
                                    {form?.userCode}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-address-card font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Email:
                                  </h6>
                                  <span className="text-muted">
                                    {form?.email}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-chalkboard-teacher font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Phone:
                                  </h6>
                                  {form?.phone}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-calendar-alt font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Date Of Joning:
                                  </h6>
                                  <Moment format="DD-MM-YYYY">
                                    {form?.logCreatedDate}
                                  </Moment>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-bookmark font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Status :</h6>
                                  {form?.status == "active" ? (
                                    <>
                                      <span className="badge bg-success">
                                        {form?.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-danger">
                                        {form?.status}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={4}>
                          <h5 className="mb-4 text-primary">
                            Customer Address:
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Alternate Phone :
                                    </h6>
                                    <p className="text-muted">
                                      {form?.alternatePhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Address
                                    </h6>
                                    <p className="text-muted">
                                      {form?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      PinCode
                                    </h6>
                                    <p className="text-muted">
                                      {form?.pinCode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={4}>
                          <h5 className="mb-4 text-primary">
                            Franchise Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Id:
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.franchiseCode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Name
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.companyName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Mobile
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.companyPhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">State</h6>
                                    <p className="text-muted">
                                      {Franchise?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      District
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.districtName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">City</h6>
                                    <p className="text-muted">
                                      {Franchise?.cityName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Address
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="mb-4 ">
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary mt-5">
                            Booking Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Type
                                    </h6>
                                    <p className="text-muted">
                                      {result?.bookingType}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Date
                                    </h6>
                                    <p className="text-muted">
                                      <Moment format="DD-MM-YYYY">
                                        {result?.purchaseDate}
                                      </Moment>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Amount
                                    </h6>
                                    <p className="text-muted">
                                      {result?.totalPrice}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary mt-5">
                            Payment Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Payment Date
                                    </h6>
                                    <p className="text-muted">
                                      <Moment format="DD-MM-YYYY">
                                        {Payment?.paymentDate}
                                      </Moment>
                                      /{Payment?.paymentTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Payment Type
                                    </h6>
                                    <p className="text-muted">
                                      {Payment?.paymentMode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Transaction Id
                                    </h6>
                                    <p className="text-muted">
                                      {Payment?.transactionId}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary mt-5">
                            Machine Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Mac Id
                                    </h6>
                                    <p className="text-muted">
                                      {form?.macAddress}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">Floor</h6>
                                    <p className="text-muted">
                                      {Machine?.floor}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Tds Value
                                    </h6>
                                    <p className="text-muted">
                                      {Machine?.tdsValue}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Water Type
                                    </h6>
                                    <p className="text-muted">
                                      {Machine?.waterType}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary mt-5">
                            Booking Track / Timing :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Assigned / Timing:
                                    </h6>
                                    <p className="text-muted">
                                      {Services?.bookingAccepted == "active" ? (
                                        <span className="text-success">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="text-warning">
                                          Pending
                                        </span>
                                      )}
                                      /{" "}
                                      {ServiceMantrack?.bookingAcceptedDate ==
                                        null ||
                                      ServiceMantrack?.bookingAcceptedDate ==
                                        "" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <Moment format="DD-MM-YYYY">
                                            {
                                              ServiceMantrack?.bookingAcceptedDate
                                            }
                                          </Moment>{" "}
                                        </>
                                      )}
                                      -{ServiceMantrack?.bookingAcceptedTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Machine Dispatched / Timing
                                    </h6>
                                    <p className="text-muted">
                                      {Services?.machineDispatched ==
                                      "active" ? (
                                        <span className="text-success">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="text-warning">
                                          Pending
                                        </span>
                                      )}
                                      /{" "}
                                      {ServiceMantrack?.dispatchDate == null ||
                                      ServiceMantrack?.dispatchDate == "" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <Moment format="DD-MM-YYYY">
                                            {ServiceMantrack?.dispatchDate}
                                          </Moment>
                                        </>
                                      )}
                                      -{ServiceMantrack?.dispatchTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Started / Timing
                                    </h6>
                                    <p className="text-muted">
                                      {Services?.serviceStarted == "active" ? (
                                        <span className="text-success">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="text-warning">
                                          Pending
                                        </span>
                                      )}
                                      /
                                      {ServiceMantrack?.serviceStartDate ==
                                        null ||
                                      ServiceMantrack?.serviceStartDate ==
                                        "" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <Moment format="DD-MM-YYYY">
                                            {ServiceMantrack?.serviceStartDate}
                                          </Moment>{" "}
                                        </>
                                      )}
                                      -{ServiceMantrack?.serviceStartTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service End / Timing
                                    </h6>
                                    <p className="text-muted">
                                      {Services?.serviceEnd == "active" ? (
                                        <span className="text-success">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="text-warning">
                                          Pending
                                        </span>
                                      )}
                                      /
                                      {ServiceMantrack?.serviceEndDate ==
                                        null ||
                                      ServiceMantrack?.serviceEndDate == "" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          <Moment format="DD-MM-YYYY">
                                            {ServiceMantrack?.serviceEndDate}
                                          </Moment>
                                        </>
                                      )}
                                      -{ServiceMantrack?.serviceEndTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <hr></hr>
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary mt-5">
                            Service Man Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Man Name :
                                    </h6>
                                    <p className="text-muted">
                                      {ServiceMan?.firstName}{" "}
                                      {ServiceMan?.lastName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Man Phone
                                    </h6>
                                    <p className="text-muted">
                                      {ServiceMan?.phone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Man Email
                                    </h6>
                                    <p className="text-muted">
                                      {ServiceMan?.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Service Man Address
                                    </h6>
                                    <p className="text-muted">
                                      {ServiceMan?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table className="table table-bordered mb-4 ">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Ticket Id</th>
                              <th>Franchise Name / Phone</th>
                              <th>Customer Name</th>
                              <th>Phone</th>
                              <th>Request Type</th>
                              <th>Description</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists2?.map((data, key) => (
                              <tr key={key}>
                                <th>{(pageNumber2 - 1) * 5 + key + 6}</th>
                                <td>{data.ticketId}</td>
                                <td>
                                  {data.franchiseName}/{data.franchisePhone}
                                </td>
                                <td>{data.userName}</td>
                                <td>{data.userPhone}</td>
                                <td>{data.requestType} </td>
                                <td>{data.description}</td>
                                <td>
                                  <span className="badge bg-success">
                                    {data.status}
                                  </span>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      viewid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="secondary"
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount2}
                            onPageChange={changePage2}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists2.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>Ticket Id</th>
                              <th>Franchise Name / Phone</th>
                              <th>Customer Name</th>
                              <th>Phone</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {lists3?.map((data, key) => (
                              <tr key={key}>
                                <th>{(pageNumber3 - 1) * 5 + key + 6}</th>
                                <td>{data.ticketId}</td>
                                <td>
                                  {data.franchiseName}/{data.franchisePhone}
                                </td>
                                <td>{data.userName}</td>
                                <td>{data.userPhone}</td>
                                <td>
                                  <span className="badge bg-success">
                                    {data.status}
                                  </span>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      viewid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="secondary"
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount3}
                            onPageChange={changePage3}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists3.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div className="table-rep-plugin table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>Plan Type</th>
                              <th>Plan Name</th>
                              <th>Plan Amount</th>
                              <th>Plan Period</th>
                              <th>Purchase Date</th>
                              <th>Expired Date</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {Plan?.map((data, key) => (
                              <tr key={key}>
                                <th>{key + 1}</th>
                                <td>
                                  {data.bookingType == null ||
                                  data.bookingType == "" ? (
                                    <>-</>
                                  ) : (
                                    <>{data.bookingType}</>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {data.planType == null ||
                                  data.planType == "" ? (
                                    <>-</>
                                  ) : (
                                    <>{data.planType}</>
                                  )}
                                </td>
                                <td>{data.totalPrice}</td>
                                <td>{data.planPeriod}</td>
                                <td>
                                  {data.purchaseDate == null ||
                                  data.purchaseDate == "" ? (
                                    <>-</>
                                  ) : (
                                    <>
                                      <Moment format="DD-MM-YYYY">
                                        {data.purchaseDate}
                                      </Moment>{" "}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {data.expiryDate == null ||
                                  data.expiryDate == "" ? (
                                    <>-</>
                                  ) : (
                                    <>
                                      <Moment format="DD-MM-YYYY">
                                        {data.expiryDate}
                                      </Moment>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
