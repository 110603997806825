import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  Invoice,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"

function BookingDetails() {
  const [activeTab1, setactiveTab1] = useState("5")
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Kanavneer Admin" breadcrumbItem="Bookings Details" />
        <Container fluid>
          <Col md={4}>
            <div className="mt-0">
              <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab1 === "5",
                    })}
                    onClick={() => {
                      toggle1("5")
                    }}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab1 === "6",
                    })}
                    onClick={() => {
                      toggle1("6")
                    }}
                  >
                    Status
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
          <TabContent activeTab={activeTab1} className="p-1 text-muted">
            <TabPane tabId="5">
              <Row>
                <Col lg="12">
                  <div className="card mb-3 mt-3">
                    <div className="card-body pb-5">
                      <div className="border-bottom pb-3 d-flex justify-content-between align-items-center gap-3 flex-wrap">
                        <div>
                          <h3 className="c1 mb-2">Booking # 100068</h3>
                          <p className="opacity-75 fz-12">
                            Booking Placed : 28-Jun-2023 01:21pm
                          </p>
                        </div>
                        <div className="d-flex flex-wrap flex-xxl-nowrap gap-3">
                          <button
                            type="button"
                            className="btn btn--primary d-none"
                            data-bs-toggle="modal"
                            id="change_schedule"
                            data-bs-target="#changeScheduleModal"
                          >
                            <span className="material-icons">schedule</span>
                            CHANGE SCHEDULE
                          </button>

                          {/* <a
                        href="https://jbfms.co.in/admin/booking/invoice/4b901320-30da-4ed7-a3b1-a4dad111cdc4"
                        className="btn btn-primary"
                        target="_blank"
                        rel="noreferrer"
                      > */}
                          {/* <span className="material-icons">description</span> */}
                          <Button>Invoice </Button>
                          {/* </a> */}
                        </div>
                      </div>
                      <div className="border-bottom py-3 d-flex justify-content-between align-items-center gap-3 flex-wrap">
                        <div>
                          <h4 className="mb-2">Payment Method</h4>
                          <h5 className="c1 mb-2">Cash after service</h5>
                          <p>
                            <span>Amount : </span> ₹472.00
                          </p>
                        </div>
                        <div>
                          <p className="mb-2">
                            <span>Booking Status :</span>{" "}
                            <span
                              className="c1 text-capitalize"
                              id="booking_status__span"
                            >
                              pending
                            </span>
                          </p>
                          <p className="mb-2">
                            <span>Payment Status : </span>{" "}
                            <span
                              className="text-danger"
                              id="payment_status__span"
                            >
                              Unpaid
                            </span>
                          </p>
                          <h5>
                            Service Schedule Date :{" "}
                            <span id="service_schedule__span">
                              28-Jun-2023 04:21pm
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="py-3 d-flex gap-3 flex-wrap mb-2">
                        <div className="c1-light-bg radius-10 py-3 px-4 flex-grow-1">
                          <h4 className="mb-2">Customer Information</h4>
                          <h5 className="c1 mb-3">balu </h5>
                          <ul className="list-info">
                            <li>
                              <span className="material-icons">
                                phone_iphone
                              </span>
                              <a href="tel:88013756987564">8328473790</a>
                            </li>
                            <li>
                              <span className="material-icons">map</span>
                              <p>
                                #721, Manjeera Majestic Commercial, KPHB, Hitec
                                City Road, Kukatpally, K P H B Phase 1,
                                Kukatpally,...
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="c1-light-bg radius-10 py-3 px-4 flex-grow-1">
                          <h4 className="mb-2">Provider Information</h4>
                          <p className="text-muted text-center mt-30 fz-12">
                            No Provider Information
                          </p>
                        </div>

                        <div className="c1-light-bg radius-10 py-3 px-4 flex-grow-1">
                          <h4 className="mb-2">Lead Service Information</h4>
                          <p className="text-muted text-center mt-30 fz-12">
                            No Serviceman Information
                          </p>
                        </div>
                      </div>
                      <h3 className="mb-3">Booking Summary</h3>
                      <div className="table-responsive border-bottom">
                        <table className="table text-nowrap align-middle mb-0">
                          <thead>
                            <tr>
                              <th className="ps-lg-3">Service</th>
                              <th>Price</th>
                              <th>Qty</th>
                              <th>Discount</th>
                              <th className="text-end">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-wrap ps-lg-3">
                                <div className="d-flex flex-column">
                                  <a
                                    href="https://jbfms.co.in/admin/service/detail/e0dd24cd-0f1e-42db-a217-96aa28481e61"
                                    className="fw-bold"
                                  >
                                    Microwave Oven Repair Service
                                  </a>
                                  <div>Charge</div>
                                </div>
                              </td>
                              <td>₹200.00</td>
                              <td>2</td>
                              <td>₹0.00</td>
                              <td className="text-end">₹472.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="row justify-content-end mt-3">
                        <div className="col-sm-10 col-md-6 col-xl-5">
                          <div className="table-responsive">
                            <table className="table-sm title-color align-right w-100">
                              <tbody>
                                <tr>
                                  <td>Sub Total (Vat Excluded)</td>
                                  <td>₹400.00</td>
                                </tr>
                                <tr>
                                  <td>Discount</td>
                                  <td>₹0.00</td>
                                </tr>
                                <tr>
                                  <td>Coupon Discount</td>
                                  <td>₹0.00</td>
                                </tr>
                                <tr>
                                  <td>Campaign Discount</td>
                                  <td>₹0.00</td>
                                </tr>
                                <tr>
                                  <td>Vat</td>
                                  <td>₹72.00</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Grand Total</strong>
                                  </td>
                                  <td>
                                    <strong>₹472.00</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="6">
              <div className="card mt-2">
                <div className="card-body">
                  <div className="border-bottom pb-3">
                    <div className="row pb-1 gy-2">
                      <div className="col-6 col-lg-3">
                        <div>
                          <h4 className="c1 mb-2 pb-1">Booking Placed</h4>
                          <p className="opacity-75">28-Jun-2023 01:21pm</p>
                        </div>
                      </div>
                      <div className="col-6 col-lg-3">
                        <div>
                          <h4 className="c1  mb-2 pb-1">Booking Status</h4>
                          <p className="opacity-75 text-capitalize">pending</p>
                        </div>
                      </div>
                      <div className="col-6 col-lg-3">
                        <div>
                          <h4 className="c1  mb-2 pb-1">Payment Status</h4>
                          <p className="opacity-75">Unpaid</p>
                        </div>
                      </div>
                      <div className="col-6 col-lg-3">
                        <div>
                          <h4 className="c1  mb-2 pb-1">Booking Amount</h4>
                          <p className="opacity-75">₹472.00</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-between mt-5">
                    <div className="col-md-6 col-xl-4 mb-5 mb-md-0">
                      <div className="timeline-wrapper">
                        <div className="timeline-steps">
                          <div className="timeline-step completed">
                            <div className="timeline-number">
                              {/* <svg
                                viewBox="0 0 512 512"
                                width="100"
                                title="check"
                              >
                                <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                              </svg> */}
                            </div>
                            <div className="timeline-info">
                              <p className="timeline-title text-capitalize">
                                Booking Placed
                              </p>
                              <p className="timeline-text">28-Jun-2023 01:21pm</p>
                              <p className="timeline-text">By - balu </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4 d-flex justify-content-center">
                      <div className="d-flex flex-column gap-30">
                        <div className="c1-light-bg radius-10 py-3 px-4">
                          <h4 className="mb-2">Lead Service Information</h4>
                          <h5 className="c1 mb-2"></h5>
                          <ul className="list-info">
                            <li>
                              {/* <span className="material-icons">phone_iphone</span> */}
                              <a href="tel:"></a>
                            </li>
                          </ul>
                        </div>
                        <div className="c1-light-bg radius-10 py-3 px-4">
                          <h4 className="mb-2">Provider Information</h4>
                          <h5 className="c1 mb-2"></h5>
                          <ul className="list-info">
                            <li>
                              {/* <span className="material-icons">phone_iphone</span> */}
                              <a href="tel:"></a>
                            </li>
                            <li>
                              {/* <span className="material-icons">map</span> */}
                              <p></p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BookingDetails
