import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table, Spinner } from "reactstrap"
import axios from "axios"
import { URLS } from "../../Url"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import ReactApexChart from "react-apexcharts"
import profileImg from "../../assets/images/profile-img.png"
import { Link } from "react-router-dom"
import Moment from "react-moment"

const Dashboard = () => {
  useEffect(() => {
    GetDashboarddata()
  }, [])

  const [Bookings, setBookings] = useState([])

  const [Topbar, setTopbar] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetDashboarddata = () => {
    var token = datas
    axios
      .post(
        URLS.GetDashboard,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setBookings(res.data.latestBookings)
        setTopbar(res.data)
        setIsLoading(false)
      })
  }

  const reports1 = [
    {
      title: "Total Franchises",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.totalFranchises,
      link: "/Franchiselist",
    },
    {
      title: "Total ServiceMans",
      iconClass: "bx-copy-alt",
      description: Topbar.totalServicemans,
      link: "/ServiceMan",
    },
    {
      title: "Total Customers",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.totalCustomers,
      link: "/Customeslist",
    },
    {
      title: "Total Amount",
      iconClass: "bx-archive-in",
      description: Topbar.totalPrice,
      link: "/PaymentReport",
    },
  ]

  const reports3 = [
    {
      title: "Pending Bookings",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.pendingBookings,
      link: "/PendingBookings",
    },
    {
      title: "Completed Bookings",
      iconClass: "bx-copy-alt",
      description: Topbar.completedBookings,
      link: "/Compleatedbookings",
    },
    {
      title: "Pending Services",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.pendingComplaints,
      link: "/Complaintbox",
    },
  ]

  const reports = [
    {
      title: "Completed Services",
      iconClass: "bx-archive-in",
      description: Topbar.completedComplaints,
      link: "/ResolvedComplaintbox",
    },
    {
      title: "Launching Plans Subscribers",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.launchingPlansSubscribers,
      link: "/LaunchingPlanSubscription",
    },

    {
      title: "Regular Plans Subscribers",
      iconClass: "bx-archive-in",
      description: Topbar.regularPlansSubscribers,
      link: "/RegularPlanSubscription",
    },
  ]

  const periodData = [
    {
      name: "Total Amount",
      data: Topbar.monthlyAmount,
    },
    {
      name: "Bookings",
      data: Topbar.monthlyBookings,
    },
  ]

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: true,
      },
    },
    colors: ["#0e4875", "#f1b44c", "#34c38f"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }

  const [form, setform] = useState([])

  useEffect(() => {
    GetProfile()
  }, [])

  const GetProfile = () => {
    var token = datas

    axios
      .post(
        URLS.getProfile,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.profileResult)
      })
  }

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "250px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title={"Kanavneer Admin"}
                breadcrumbItem={"Dashboard"}
              />
              <Row>
                <Col xl="4">
                  <Link to="/profile">
                    <Card className="overflow-hidden">
                      <div className="bg-primary bg-soft">
                        <Row>
                          <Col xs="7">
                            <div className="text-primary p-2">
                              <p className="text-primary">
                                <b>Welcome Back {form.name} !</b>
                              </p>
                            </div>
                          </Col>
                          <Col xs="5" className="align-self-end">
                            <img
                              src={profileImg}
                              alt=""
                              className="img-fluid"
                            />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <Row>
                          <Col sm="12">
                            <div className="avatar-md profile-user-wid mb-2">
                              <img
                                src={URLS.Base + form.profilePic}
                                style={{ height: "80px" }}
                                className="img-thumbnail rounded-circle"
                              />
                            </div>
                            <p>Name : {form.name} </p>
                            <p>Phone : {form.phone} </p>
                            <p>Address : {form.address} </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col md="8">
                  <Row>
                    {reports1.map((report, key) => (
                      <Col md="6" key={"_col_" + key}>
                        <Link to={report.link}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-dark fw-medium ">
                                    {report.title}
                                  </p>
                                  <h4 className="mb-0 text-primary">
                                    {report.description}
                                  </h4>
                                </div>
                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                      className={
                                        "bx " +
                                        report.iconClass +
                                        " font-size-24"
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Col>

                {reports3.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Link to={report.link}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p
                                className="text-dark fw-medium"
                                style={{ fontSize: "12px" }}
                              >
                                {report.title}
                              </p>
                              <h4 className="mb-0 text-primary">
                                {report.description}
                              </h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ))}

                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Link to={report.link}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p
                                className="text-dark fw-medium"
                                style={{ fontSize: "12px" }}
                              >
                                {report.title}
                              </p>
                              <h4 className="mb-0 text-primary">
                                {report.description}
                              </h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ))}

                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">Sales</h4>
                    </div>

                    <ReactApexChart
                      options={options}
                      series={[...periodData]}
                      type="bar"
                      height="359"
                      className="apex-charts"
                    />
                  </CardBody>
                </Card>
              </Row>

              <Row className="mb-5">
                <Col lg="12 ">
                  <Card>
                    <CardBody>
                      <div className="table-rep-plugin mt-2 table-responsive">
                        <h5>Latest Bookings</h5>
                        <Table hover className="table table-bordered mb-4 mt-5">
                          <thead>
                            <tr className="text-center">
                              <th>Sl.No</th>
                              <th>Customer Name</th>
                              <th>Phone</th>
                              <th>Address</th>
                              <th>Subscribe Date </th>
                              <th>Franchise Details</th>
                              <th>Status </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Bookings.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{key + 1}</th>
                                <td>{data.customerName}</td>
                                <td>{data.customerPhone}</td>
                                <td>{data.customerAddress}</td>
                                <td>
                                  <Moment format="DD-MM-YYYY">
                                    {data.purchaseDate}
                                  </Moment>
                                </td>
                                <td>
                                  {data.franchiseName} - {data.franchisePhone}
                                </td>
                                <td>
                                  {data.status == "Registration Done" ? (
                                    <>
                                      <span className="badge bg-warning">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-success">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
