import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select"
import { URLS } from "../../Url"

const Enquaries = () => {
  const [Noti, setNoti] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token


  const getNotifications = () => {
    var token = datas
    axios
      .post(URLS.Getallenquiries, {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.enquirys)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getNotifications()
  }, [])

  const deletenoti = (data) => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.Deleteenquiries + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getNotifications()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletenoti(data)
    }
  }



  const [forms, setforms] = useState([])

  const handlechange = (e) => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(URLS.Getallenquiriessearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.enquirys)
      })
  }

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Noti.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Noti.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Kanavneer Admin" breadcrumbItem="Get Enquaries" />
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <Spinner
                  style={{ marginTop: "120px" }}
                  className="ms-2"
                  animation="border"
                  color="primary"
                  size="lg"
                />{" "}
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row className="mb-4">
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col></Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={forms.search}
                              onChange={handlechange}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover bordered responsive>
                          <thead>
                            <tr  className="text-center">
                              <th>Sl No</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Location</th>
                              <th>Current Business</th>
                              <th>Message</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th scope="row">
                                  {(pageNumber - 1) * 20 + key + 21}
                                </th>
                                <td>{data.name}</td>
                                <td> {data.email} </td>
                                <td> {data.phoneNumber} </td>
                                <td> {data.location} </td>
                                <td> {data.currentBusiness} </td>
                                <td> {data.description} </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      manageDelete(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="danger"
                                  >
                                    <div className="d-flex">
                                      <i className="bx bx-trash"></i>
                                      <small>Delete</small>
                                    </div>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Enquaries
