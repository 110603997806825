import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import trash from "../../assets/images/trash.gif"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"

const ResponsiveTables = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [show2, setshow2] = useState(false)
  const toggle2 = () => setshow2(!show2)

  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])

  const [plans, setplans] = useState([])
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetCustomers,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.custResult)
        setIsLoading(false)
      })
  }

  const datapass = data => {
    setform2(data)
    setshow1(true)
  }

  const getPlandelete = () => {
    const params = form2._id

    var token = datas
    axios
      .put(
        URLS.DeleteCustomers + params,
        { status: "inactive" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const datapass1 = data => {
    setform2(data)
    setshow2(true)
  }

  const getPlandelete1 = () => {
    const params = form2._id

    var token = datas
    axios
      .put(
        URLS.DeleteCustomers + params,
        { status: "active" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setshow2(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas

    axios
      .post(
        URLS.getCustomersidsearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.custResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  useEffect(() => {
    getPlans()
    datass()
  }, [])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const viewid = data => {
    history.push("/ViewCustomers", sessionStorage.setItem("CustId", data._id))
  }

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "250px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs
                title="Kanavneer Admin"
                breadcrumbItem="Customer list"
              />
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={planssearch}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Customer Name </th>
                              <th>Customer Phone</th>
                              <th>Customer Email</th>
                              <th>Customer Address</th>
                              <th>Franchise Details</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                <td>{data.customerName}</td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>{data.address}</td>
                                <td>
                                  {data.franchiseName}-{data.franchisePhone}
                                </td>
                                <td>{data.status}</td>
                                <td style={{width:"100px"}}>
                                  <Button
                                    onClick={() => {
                                      viewid(data)
                                    }}
                                    className="btn-sm"
                                    color="secondary"
                                  >
                                    <i className="fas fa-eye"></i>{" "}
                                    <small>View</small>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <ToastContainer />
            </div>
          </div>
          <Modal size="sm" isOpen={show1} toggle={toggle} centered>
            <ModalBody>
              <div>
                <div className="text-center">
                  <img style={{ width: "280px" }} src={trash} />
                </div>
                <h5 className="text-center">Do you want Delete Customer</h5>
                <div className="text-end mt-2">
                  <Button
                    onClick={() => {
                      getPlandelete()
                    }}
                    type="button"
                    color="danger m-1"
                    outline
                  >
                    Yes <i className="bx bx-check-circle"></i>
                  </Button>
                  <Button
                    type="button"
                    onClick={toggle}
                    color="secondary m-1"
                    outline
                  >
                    Cancel <i className="bx bx-x-circle"></i>
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal size="sm" isOpen={show2} toggle={toggle2} centered>
            <ModalBody>
              <div>
                <div className="text-center">
                  <img style={{ width: "280px" }} src={trash} />
                </div>
                <h5 className="text-center">Do you want Active</h5>
                <div className="text-end mt-2">
                  <Button
                    onClick={() => {
                      getPlandelete1()
                    }}
                    type="button"
                    color="danger m-1"
                    outline
                  >
                    Yes <i className="bx bx-check-circle"></i>
                  </Button>
                  <Button
                    type="button"
                    onClick={toggle2}
                    color="secondary m-1"
                    outline
                  >
                    Cancel <i className="bx bx-x-circle"></i>
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </React.Fragment>
  )
}

export default ResponsiveTables
