import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import classnames from "classnames"
import { URLS } from "../../Url"
import axios from "axios"
import Moment from "react-moment"

function ViewFranchise() {
  const [activeTab1, setactiveTab1] = useState("1")

  const [isLoading, setIsLoading] = useState(true)

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  const [plans, setplans] = useState([])

  const [Pincode, setPincode] = useState([])

  const [PendingBookings, setPendingBookings] = useState([])
  const [CompletedBookings, setCompletedBookings] = useState([])
  const [PendingServices, setPendingServices] = useState([])
  const [CompletedServices, setCompletedServices] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getPlans()
  }, [])

  const getPlans = () => {
    const dataArray = new FormData()
    dataArray.append("_id", sessionStorage.getItem("franchid"))

    var token = datas
    axios
      .post(URLS.getFranchiseid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setIsLoading(false)
        setplans(res.data.franchResult)
        setPendingBookings(res.data.pendingBookings)
        setCompletedBookings(res.data.completedBookings)
        setPendingServices(res.data.pendingComplaints)
        setCompletedServices(res.data.completedComplaints)
        setPincode(res.data.franchResult.pincode)
      })
  }
  const history = useHistory()

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = PendingBookings.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(PendingBookings.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [listPerPage1] = useState(20)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = CompletedBookings.slice(
    pagesVisited1,
    pagesVisited1 + listPerPage1
  )
  const pageCount1 = Math.ceil(CompletedBookings.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const [listPerPage2] = useState(20)
  const [pageNumber2, setPageNumber2] = useState(0)

  const pagesVisited2 = pageNumber2 * listPerPage2
  const lists2 = PendingServices.slice(
    pagesVisited2,
    pagesVisited2 + listPerPage2
  )
  const pageCount2 = Math.ceil(PendingServices.length / listPerPage2)
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected)
  }

  const [listPerPage3] = useState(20)
  const [pageNumber3, setPageNumber3] = useState(0)

  const pagesVisited3 = pageNumber3 * listPerPage3
  const lists3 = CompletedServices.slice(
    pagesVisited3,
    pagesVisited3 + listPerPage3
  )
  const pageCount3 = Math.ceil(CompletedServices.length / listPerPage3)
  const changePage3 = ({ selected }) => {
    setPageNumber3(selected)
  }

  const viewid = data => {
    history.push(
      "/ViewRegistationBookings",
      sessionStorage.setItem("RegisterId", data._id)
    )
  }

  const viewid1 = data => {
    history.push(
      "/ViewComplaintbox",
      sessionStorage.setItem("ServiceId", data._id)
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Kanavneer Admin"
            breadcrumbItem="View Franchise"
          />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <Spinner
                  style={{ marginTop: "20px" }}
                  className="ms-2"
                  animation="border"
                  color="primary"
                  size="lg"
                />{" "}
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "1",
                            })}
                            onClick={() => {
                              toggle1("1")
                            }}
                          >
                            Franchise Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "2",
                            })}
                            onClick={() => {
                              toggle1("2")
                            }}
                          >
                            Pending Booking
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "3",
                            })}
                            onClick={() => {
                              toggle1("3")
                            }}
                          >
                            Completed Booking
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "4",
                            })}
                            onClick={() => {
                              toggle1("4")
                            }}
                          >
                            Pending Services
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "4px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            Completed Services
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <TabContent
                    activeTab={activeTab1}
                    className="pt-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row className="mb-4">
                        <Col lg={4}>
                          <h5 className="mb-4 text-primary">
                            Company Profile:
                          </h5>
                          <ul className="list-unstyled vstack gap-3 mb-0">
                            <a
                              href={URLS.Base + plans.companyLogo}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={URLS.Base + plans.companyLogo}
                                width="150px"
                                height="150px"
                              ></img>
                            </a>

                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bxs-info-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Franchise Id:{" "}
                                  </h6>
                                  <span className="text-muted">
                                    {plans.franchiseCode}
                                  </span>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-address-book font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Name: </h6>
                                  <span className="text-muted">
                                    {plans.userName}
                                  </span>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-address-card font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Email:</h6>
                                  <span className="text-muted">
                                    {plans.email}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-atlas font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Support Email:
                                  </h6>
                                  <span className="text-muted">
                                    {plans.supportEmail}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-chalkboard-teacher font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Phone:</h6>
                                  {plans.phone}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-calendar-alt font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Date Of Joining:
                                  </h6>
                                  <Moment format="DD-MM-YYYY">
                                    {plans.logCreatedDate}
                                  </Moment>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="fas fa-book-open font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Gst Number :
                                  </h6>
                                  {plans.companyGstNumber}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-bookmark font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Status :</h6>
                                  {plans.status == "active" ? (
                                    <>
                                      <span className="badge bg-success">
                                        {plans.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-danger">
                                        {plans.status}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={4}>
                          <h5 className="mb-4 text-primary">
                            Franchise Details:
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Franchise Name
                                    </h6>
                                    <p className="text-muted">
                                      {plans.companyName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Franchise Phone
                                    </h6>
                                    <p className="text-muted">
                                      {plans.companyPhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Booking Email
                                    </h6>
                                    <p className="text-muted">
                                      {plans.bookingEmail}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">State</h6>
                                    <p className="text-muted">
                                      {plans.stateName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      District
                                    </h6>
                                    <p className="text-muted">
                                      {plans.districtName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">City</h6>
                                    <p className="text-muted">
                                      {plans.cityName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Address
                                    </h6>
                                    <p className="text-muted">
                                      {plans.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      PinCode
                                    </h6>
                                    {Pincode.map((data, key) => (
                                      <p className="text-muted pt-2" key={key}>
                                        {data.label},
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={4}>
                          <h5 className="mb-4 text-primary">Bank Details:</h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Razorpay Account Id
                                    </h6>
                                    <p className="text-muted">
                                      {plans.razoraccountId}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">Bank Name</h6>
                                    <p className="text-muted">
                                      {plans.bankName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Account Holder Name
                                    </h6>
                                    <p className="text-muted">
                                      {plans.accountHolderName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Bank Account Number
                                    </h6>
                                    <p className="text-muted">
                                      {plans.bankAccountNumber}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      IFSC Code{" "}
                                    </h6>
                                    <p className="text-muted">
                                      {plans.ifscCode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      {" "}
                                      Bank Account Type
                                    </h6>
                                    <p className="text-muted">
                                      {plans.bankAccountType}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Pan Number
                                    </h6>
                                    <p className="text-muted">
                                      {plans.panNumber}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Company Id Image
                                    </h6>
                                    <p className="text-muted">
                                      <a
                                        href={URLS.Base + plans.companyIdImage}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <img
                                          src={URLS.Base + plans.companyIdImage}
                                          width="100px"
                                        ></img>
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>Sl.No</th>
                              <th>Customer Name</th>
                              <th>Phone</th>
                              <th>Address</th>
                              <th>Subscribe Date </th>
                              <th>Status </th>
                              <th>Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                <td>{data.customerName}</td>
                                <td>{data.customerPhone}</td>
                                <td>{data.customerAddres}</td>
                                <td>
                                  <Moment format="DD-MM-YYYY">
                                    {data.purchaseDate}
                                  </Moment>
                                </td>
                                <td>
                                  {data.status == "Registration Done" ? (
                                    <>
                                      <span className="badge bg-warning">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-success">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      viewid(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="secondary"
                                  >
                                    <i className="fas fa-eye"></i> view
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>Sl.No</th>
                              <th>Customer Name</th>
                              <th>Phone</th>
                              <th>Address</th>
                              <th>Subscribe Date </th>
                              <th>Service Man Details</th>
                              <th>Status </th>
                              <th>Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists1.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                <td>{data.customerName}</td>
                                <td>{data.customerPhone}</td>
                                <td>{data.customerAddres}</td>
                                <td>
                                  <Moment format="DD-MM-YYYY">
                                    {data.purchaseDate}
                                  </Moment>
                                </td>
                                <td>
                                  {data.serviceManFirstName}
                                  {data.serviceManLastName} -{" "}
                                  {data.serviceManPhone}
                                </td>
                                <td>
                                  {data.status == "Registration Done" ? (
                                    <>
                                      <span className="badge bg-warning">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-success">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      viewid(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="secondary"
                                  >
                                    <i className="fas fa-eye"></i> view
                                  </Button> 
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount1}
                            onPageChange={changePage1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists1.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table className="table table-bordered mb-4 ">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Ticket Id</th>
                              <th>Customer Name</th>
                              <th>Phone</th>
                              <th>Request Type</th>
                              <th>Description</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists2.map((data, key) => (
                              <tr key={key}>
                                <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                <td>{data.ticketId}</td>
                                <td>{data.userName}</td>
                                <td>{data.userPhone}</td>
                                <td>{data.requestType} </td>
                                <td>{data.description}</td>
                                <td>
                                  <span className="badge bg-warning">
                                    {data.status}
                                  </span>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      viewid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="secondary"
                                  >
                                    <i className="fas fa-eye"></i> view
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount2}
                            onPageChange={changePage2}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists2.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>Ticket Id</th>
                              <th>Customer Name</th>
                              <th>Phone</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {lists3.map((data, key) => (
                              <tr key={key}>
                                <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                <td>{data.ticketId}</td>
                                <td>{data.userName}</td>
                                <td>{data.userPhone}</td>
                                <td>
                                  <span className="badge bg-success">
                                    {data.status}
                                  </span>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      viewid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="info"
                                  >
                                    <i className="fas fa-eye"></i> view
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount3}
                            onPageChange={changePage3}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists3.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>{" "}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewFranchise
