import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Table,
  Label,
  Input,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"

function TermsConditions() {
  const [form, setform] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetSettings()
  }, [])

  const GetSettings = () => {
    var token = datas
    const dataArray = new FormData()

    axios
      .post(URLS.GetBusinessSetting, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.businessSetting)
        setIsLoading(false)
      })
  }

  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [text1, setText1] = useState([])

  const getpopup1 = () => {
    setText1(form)
    tog_small()
  }

  const handlechange = e => {
    const myform = { ...text1 }
    myform[e.target.name] = e.target.value
    setText1(myform)
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }

  const [Files, setFiles] = useState("")

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changstatus = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("companyName", text1.companyName)
    dataArray.append("companyEmail", text1.companyEmail)
    dataArray.append("companySupportEmail", text1.companySupportEmail)
    dataArray.append("companyAddress", text1.companyAddress)
    dataArray.append("splitPercentage", text1.splitPercentage)

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("companyLogo", Files[i])
    }
    axios
      .put(URLS.UpdateBusinessSetting, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetSettings()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "120px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title="Kanavneer Admin"
                breadcrumbItem="Business Setting"
              />
              <Row>
                <Col md={12}>
                  <Card>
                    <CardHeader className="bg-white"></CardHeader>
                    <CardBody>
                      <div style={{ float: "right" }}>
                        <Button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit Booking"
                          onClick={() => {
                            getpopup1(form)
                          }}
                          className="mr-5"
                          color="info"
                        >
                          <i className="bx bx-edit text-white"></i>
                          <span>Edit</span>
                        </Button>
                      </div>
                      <div>
                        <div>
                          <h5>Business Setting</h5>
                          <Table
                            hover
                            className="table table-bordered mb-4 mt-5"
                          >
                            <thead>
                              <tr className="text-center">
                                <th>SNO</th>
                                <th>Company</th>
                                <th>Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="text-center">
                                <th>1)</th>
                                <th>Logo</th>
                                <td>
                                  <img
                                    src={URLS.Base + form.companyLogo}
                                    alt=""
                                  />
                                </td>
                              </tr>
                              <tr className="text-center">
                                <th>2)</th>
                                <th>Name</th>
                                <td>{form.companyName}</td>
                              </tr>
                              <tr className="text-center">
                                <th>3)</th>
                                <th>Email</th>
                                <td>{form.companyEmail}</td>
                              </tr>
                              <tr className="text-center">
                                <th>4)</th>
                                <th>Support Email</th>
                                <td>{form.companySupportEmail}</td>
                              </tr>
                              <tr className="text-center">
                                <th>5)</th>
                                <th>Franchise Split Percentage</th>
                                <td>{form.splitPercentage} %</td>
                              </tr>
                              <tr className="text-center">
                                <th>6)</th>
                                <th>Address</th>
                                <td>{form.companyAddress}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Modal
                size="md"
                isOpen={modal_small}
                toggle={() => {
                  tog_small()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Edit Business Setting
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <Form
                  onSubmit={e => {
                    submibooking(e)
                  }}
                >
                  <div className="modal-body">
                    <Row>
                      {" "}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Company Name</Label>{" "}
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handlechange(e)
                            }}
                            name="companyName"
                            required
                            type="text"
                            value={text1.companyName}
                            placeholder="Enter Company Name"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Company Email</Label>{" "}
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handlechange(e)
                            }}
                            name="companyEmail"
                            required
                            type="text"
                            value={text1.companyEmail}
                            placeholder="Enter Company Email"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Company Support Email</Label>{" "}
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handlechange(e)
                            }}
                            name="companySupportEmail"
                            required
                            type="text"
                            value={text1.companySupportEmail}
                            placeholder="Enter Company Support Email"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Franchise Split Percentage</Label>{" "}
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handlechange(e)
                            }}
                            name="splitPercentage"
                            required
                            type="text"
                            value={text1.splitPercentage}
                            placeholder="Enter Franchise Split Percentage"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Company Address</Label>{" "}
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handlechange(e)
                            }}
                            name="companyAddress"
                            required
                            type="text"
                            value={text1.companyAddress}
                            placeholder="Enter Company Address"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Company Logo</Label>{" "}
                          <span className="text-danger">*</span>
                          <Input
                            type="file"
                            className="form-control"
                            name="planImage"
                            value={text1.planImage}
                            onChange={changeHandler1}
                          />{" "}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr></hr>
                  <div className=" mb-2" style={{ float: "right" }}>
                    <button
                      type="submit"
                      style={{ width: "120px" }}
                      className="btn btn-info m-1"
                    >
                      Submit <i className="fas fa-check-circle"></i>
                    </button>
                  </div>
                </Form>
              </Modal>
            </Container>
            <ToastContainer />
          </div>{" "}
        </>
      )}
    </React.Fragment>
  )
}

export default TermsConditions
