import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Form,
  Modal,
  Label,
  ModalBody,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"
import trash from "../../assets/images/trash.gif"

const ResponsiveTables = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [form1, setform1] = useState([])
  const [plans, setplans] = useState([])
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const history = useHistory()

  const getPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetServiceman,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.serviceResult)
        setIsLoading(false)
      })
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas

    axios
      .post(
        URLS.GetServicemanSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.serviceResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    getPlans()
    datass()
  }, [])

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const plansid = data => {
    sessionStorage.setItem("Serviceid", data._id)
    history.push("/EditServiceman")
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const EditBanner = () => {
    var token = datas
    var formid = form1._id
    const dataArray = new FormData()
    dataArray.append("franchiseId", form1.franchiseId)

    axios
      .put(URLS.AssignServiceman + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [ServiceMan, setServiceMan] = useState([])

  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [show2, setshow2] = useState(false)
  const toggle2 = () => setshow2(!show2)

  useEffect(() => {
    getServiceMan()
  }, [])

  const getServiceMan = () => {
    var token = datas
    axios
      .post(
        URLS.GetFranchise,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setServiceMan(res.data.franchResult)
      })
  }

  const [form2, setform2] = useState([])

  const datapass = data => {
    setform2(data)
    setshow1(true)
  }

  const getPlandelete = () => {
    const params = form2._id

    var token = datas
    axios
      .put(
        URLS.statusServiceman + params,
        { status: "inactive" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const datapass1 = data => {
    setform2(data)
    setshow2(true)
  }

  const getPlandelete1 = () => {
    const params = form2._id

    var token = datas
    axios
      .put(
        URLS.statusServiceman + params,
        { status: "active" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setshow2(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const viewid = data => {
    history.push(
      "/ViewServiceMan",
      sessionStorage.setItem("ServiceManId", data._id)
    )
  }

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "250px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs
                title="Kanavneer Admin"
                breadcrumbItem="Service Man List"
              />
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <Link to="/AddServiceMan">
                            <Button color="primary">
                              New Serviceman{" "}
                              <i className="bx bx-plus-circle"></i>
                            </Button>
                          </Link>
                        </Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={planssearch}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Service Man Image</th>
                              <th>Service Man Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Franchise Details </th>
                              <th>Status </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                <td>
                                  <img
                                    src={URLS.Base + data.profilePic}
                                    height="80px"
                                    width="100px"
                                  ></img>
                                </td>
                                <td>
                                  {data.firstName} {""}
                                  {data.lastName}
                                </td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>
                                  {data.franchiseName}-{data.franchisePhone}
                                </td>
                                <td>{data.status}</td>
                                <td style={{ width: "400px" }}>
                                  <Button
                                    onClick={() => {
                                      plansid(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="info"
                                  >
                                    <div className="d-flex">
                                      <i className="bx bx-edit"></i>{" "}
                                      <small>Edit</small>
                                    </div>
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      viewid(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="secondary"
                                  >
                                    <div className="d-flex">
                                      <i className="fas fa-eye"></i>{" "}
                                      <small>View</small>
                                    </div>
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      getpopup(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    style={{
                                      background: "#FF8C00",
                                      border: "#FF8C00",
                                    }}
                                  >
                                    <div className="d-flex">
                                      <i className="bx bxs-user-detail"></i>{" "}
                                      <small>Assigning</small>
                                    </div>
                                  </Button>
                                  {data.status === "active" ? (
                                    <Button
                                      onClick={() => {
                                        datapass(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      color="success"
                                    >
                                      <div className="d-flex">
                                        <i className="bx bxs-notification-off"></i>{" "}
                                        <small>Active</small>
                                      </div>
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        datapass1(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      color="danger"
                                    >
                                      <div className="d-flex">
                                        <i className="bx bxs-check-square"></i>{" "}
                                        <small>Inactive</small>
                                      </div>
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Assigned Service Man
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <Col md={12}>
                  <div className="mb-3">
                    <Label> Franchise Name</Label>{" "}
                    <span className="text-danger">*</span>
                    <select
                      value={form1.franchiseId}
                      name="franchiseId"
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                      required
                    >
                      <option value="">Select</option>
                      {ServiceMan.map((data, key) => {
                        return (
                          <option key={key} value={data._id}>
                            {data.companyName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal size="sm" isOpen={show1} toggle={toggle} centered>
            <ModalBody>
              <div>
                <div className="text-center">
                  <img style={{ width: "280px" }} src={trash} />
                </div>
                <h5 className="text-center">Do you want Inactive</h5>
                <div className="text-end mt-2">
                  <Button
                    onClick={() => {
                      getPlandelete()
                    }}
                    type="button"
                    color="danger m-1"
                    outline
                  >
                    Yes <i className="bx bx-check-circle"></i>
                  </Button>
                  <Button
                    type="button"
                    onClick={toggle}
                    color="secondary m-1"
                    outline
                  >
                    Cancel <i className="bx bx-x-circle"></i>
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal size="sm" isOpen={show2} toggle={toggle2} centered>
            <ModalBody>
              <div>
                <div className="text-center">
                  <img style={{ width: "280px" }} src={trash} />
                </div>
                <h5 className="text-center">Do you want Active</h5>
                <div className="text-end mt-2">
                  <Button
                    onClick={() => {
                      getPlandelete1()
                    }}
                    type="button"
                    color="danger m-1"
                    outline
                  >
                    Yes <i className="bx bx-check-circle"></i>
                  </Button>
                  <Button
                    type="button"
                    onClick={toggle2}
                    color="secondary m-1"
                    outline
                  >
                    Cancel <i className="bx bx-x-circle"></i>
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
      <ToastContainer />
    </React.Fragment>
  )
}

export default ResponsiveTables
