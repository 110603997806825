import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { URLS } from "Url"
import { useHistory } from "react-router-dom"
import classnames from "classnames"
import ReactPaginate from "react-paginate"

function AddVendors() {
  const [Customer, setCustomer] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [Franchise, setFranchise] = useState([])

  const [Payment, setPayment] = useState([])

  const [plans, setplans] = useState([])

  const [PlanDetails, setPlanDetails] = useState([])

  const [activeTab1, setactiveTab1] = useState("1")

  const [Topups, setTopups] = useState([])

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getPlansid()
  }, [])

  const plansid = sessionStorage.getItem("Subids")

  const getPlansid = () => {
    const dataArray = {
      Id: plansid,
    }
    var token = datas
    axios
      .post(URLS.GetBuySubscribeId, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setIsLoading(false)
        setCustomer(res.data?.details?.customer)
        setFranchise(res.data?.details?.franchise)
        setPayment(res.data?.details)
        setplans(res.data?.details?.launchingplan)
        setPlanDetails(res.data?.periodDetails)
        setTopups(res.data?.details?.topUps)
      })
  }

  const history = useHistory()

  const [listPerPage2] = useState(20)
  const [pageNumber2, setPageNumber2] = useState(0)

  const pagesVisited2 = pageNumber2 * listPerPage2
  const lists2 = Topups.slice(pagesVisited2, pagesVisited2 + listPerPage2)
  const pageCount2 = Math.ceil(Topups.length / listPerPage2)
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Kanavneer Admin"
            breadcrumbItem="View Subscriber"
          />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <Spinner
                  style={{ marginTop: "20px" }}
                  className="ms-2"
                  animation="border"
                  color="primary"
                  size="lg"
                />{" "}
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "5px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "1",
                            })}
                            onClick={() => {
                              toggle1("1")
                            }}
                          >
                            Customer Details / Franchise Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "5px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "2",
                            })}
                            onClick={() => {
                              toggle1("2")
                            }}
                          >
                            Plans Details / Payment Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          style={{
                            border: "1px #0E4875 solid",
                            borderRadius: "5px",
                            padding: "2px",
                            margin: "5px",
                          }}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "3",
                            })}
                            onClick={() => {
                              toggle1("3")
                            }}
                          >
                            TopUp
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                  <TabContent
                    activeTab={activeTab1}
                    className="pt-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row className="mb-4 mt-4">
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary">
                            Customer Profile:
                          </h5>
                          <ul className="list-unstyled vstack gap-3 mb-0">
                            <a
                              href={URLS.Base + Customer?.profilePic}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={URLS.Base + Customer?.profilePic}
                                width="120px"
                                height="120px"
                              ></img>
                            </a>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Name:{" "}
                                  </h6>
                                  <span className="text-muted">
                                    {Customer?.customerName}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Email:
                                  </h6>
                                  <span className="text-muted">
                                    {Customer?.email}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Customer Phone:
                                  </h6>
                                  {Customer?.phone}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    Address :
                                  </h6>
                                  {Customer?.address}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">
                                    PinCode :
                                  </h6>
                                  {Customer?.pinCode}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex mt-4">
                                <i className="bx bx-log-in-circle font-size-18 text-primary"></i>
                                <div className="ms-3">
                                  <h6 className="mb-1 fw-semibold">Status :</h6>
                                  <span className="badge bg-warning">
                                    {Customer?.runningStatus}
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary">
                            Franchise Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Code
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.franchiseCode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Name
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.companyName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Mobile
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.companyPhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Franchise Email
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.companyPhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">State</h6>
                                    <p className="text-muted">
                                      {Franchise?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      District
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.districtName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">City</h6>
                                    <p className="text-muted">
                                      {Franchise?.cityName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Address
                                    </h6>
                                    <p className="text-muted">
                                      {Franchise?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="2">
                      <Row className="mb-4 mt-3 ">
                        <Col lg={12}>
                          <Row>
                            <Col md={4}>
                              <ul className="list-unstyled vstack gap-3 mb-0">
                                <h5 className="mb-4 text-primary mb-2">
                                  Plan Details :-
                                </h5>
                                <a
                                  href={URLS.Base + plans?.planImage}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={URLS.Base + plans?.planImage}
                                    width="100px"
                                    height="100px"
                                  ></img>
                                </a>
                                <li>
                                  <div className="d-flex mt-4">
                                    <i className="bx bxs-check-shield font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Plan Name :{" "}
                                      </h6>
                                      <span className="text-muted">
                                        {plans?.planType}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex mt-4">
                                    <i className="bx bxs-check-shield font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Plan Type :{" "}
                                      </h6>
                                      <span className="text-muted">
                                        {Payment?.bookingType}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                            <Col lg={8}>
                              <h4 className="mb-4 text-primary">
                                Plan Benifits :-
                              </h4>
                              <div className="table-rep-plugin mt-4 table-responsive">
                                <Table hover className="table table-bordered ">
                                  <thead>
                                    <tr className="text-center">
                                      <th style={{ fontWeight: "bold" }}>
                                        Title
                                      </th>
                                      <th style={{ fontWeight: "bold" }}>
                                        Benifits
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="text-center">
                                      <td style={{ fontWeight: "bold" }}>
                                        Months
                                      </td>
                                      <td>{Payment?.planPeriod}</td>
                                    </tr>
                                    <tr className="text-center">
                                      <td style={{ fontWeight: "bold" }}>
                                        Recharge
                                      </td>
                                      <td>{PlanDetails?.MonthPrice}</td>
                                    </tr>
                                    <tr className="text-center">
                                      <td style={{ fontWeight: "bold" }}>
                                        Bonus Days
                                      </td>
                                      <td>{PlanDetails?.MonthBonusDays}</td>
                                    </tr>
                                    <tr className="text-center">
                                      <td style={{ fontWeight: "bold" }}>
                                        Bonus Liters
                                      </td>
                                      <td>{PlanDetails?.MonthBonusLitrs}</td>
                                    </tr>
                                    <tr className="text-center">
                                      <td style={{ fontWeight: "bold" }}>
                                        Total Liters
                                      </td>
                                      <td>{PlanDetails?.MonthTotalLitrs}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row className="mb-5">
                        <Col lg={6}>
                          <h5 className="mb-4 text-primary mt-5">
                            Payment Details :
                          </h5>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Plan Total Amount
                                    </h6>
                                    <p className="text-muted">
                                      {Payment?.totalPrice}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Purchasing Date
                                    </h6>
                                    <p className="text-muted">
                                      {Payment?.purchaseDate}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Expire Date
                                    </h6>
                                    <p className="text-muted">
                                      {Payment?.expiryDate}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="3">
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>Plan Type</th>
                              <th>Purchase Date / Time</th>
                              <th>Transaction Id</th>
                              <th>Total Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists2?.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber2 - 1) * 5 + key + 6}</th>
                                <td>{data.planType}</td>
                                <td>
                                  {data.purchaseDate} / {data.purchaseTime}
                                </td>
                                <td>{data.transactionId}</td>
                                <td>{data.totalPrice}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount2}
                            onPageChange={changePage2}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists2.length}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
