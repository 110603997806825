import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import classnames from "classnames"
import VendorRefundPolicy from "./VendorRefundPolicy"

function TermsConditions() {
  const [activeTab1, setactiveTab1] = useState("1")
  const [isLoading, setIsLoading] = useState(true)
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [form, setform] = useState([])

  useEffect(() => {
    GetSettings()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetSettings = () => {
    var token = datas
    const dataArray = new FormData()

    axios
      .post(URLS.GetSetting, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.setting.refundPolicy)
        setIsLoading(false)
      })
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const [text1, setText1] = useState([])

  const getpopup1 = forms => {
    setText1(forms)
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }

  const changstatus = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("refundPolicy", text1)

    axios
      .put(URLS.UpdaterefundPolicy, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetSettings()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "120px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title="Kanavneer Admin"
                breadcrumbItem="Refund policy"
              />
              <Row>
                <Col md={12}>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem
                      style={{
                        border: "1px #0E4875 solid",
                        borderRadius: "5px",
                        padding: "2px",
                        margin: "4px",
                      }}
                    >
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "1",
                        })}
                        onClick={() => {
                          toggle1("1")
                        }}
                      >
                        User Refund Policy
                      </NavLink>
                    </NavItem>
                    <NavItem
                      style={{
                        border: "1px #0E4875 solid",
                        borderRadius: "5px",
                        padding: "2px",
                        margin: "4px",
                      }}
                    >
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "2",
                        })}
                        onClick={() => {
                          toggle1("2")
                        }}
                      >
                        Vendor Refund Policy
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <TabContent activeTab={activeTab1} className="pt-3 text-muted">
                <TabPane tabId="1">
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardHeader className="bg-white"></CardHeader>

                        <CardBody>
                          <div style={{ float: "right" }}>
                            <Button
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Edit Booking"
                              onClick={() => {
                                getpopup1(form)
                              }}
                              className="mr-5"
                              color="info"
                            >
                              <i className="bx bx-edit text-white "></i>
                              <span>Edit</span>
                            </Button>{" "}
                          </div>
                          <div>
                            <div>
                              <h5>User Refund policy</h5>
                              <div
                                className="mt-5"
                                dangerouslySetInnerHTML={{
                                  __html: form,
                                }}
                              ></div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <VendorRefundPolicy />
                </TabPane>
              </TabContent>
              <Modal
                size="lg"
                isOpen={modal_small}
                toggle={() => {
                  tog_small()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Refund policy Edit
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <Form
                  onSubmit={e => {
                    submibooking(e)
                  }}
                >
                  <div className="modal-body">
                    <CKEditor
                      editor={ClassicEditor}
                      id="header"
                      data={text1}
                      onReady={editor => {
                        console.log("Editor is ready to use!", editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setText1(data)
                      }}
                    />
                  </div>

                  <hr></hr>
                  <div style={{ float: "right" }} className="m-2">
                    <Button className="m-1" color="primary" type="submit">
                      Submit <i className="fas fa-check-circle"></i>
                    </Button>
                    <Button
                      onClick={() => {
                        setmodal_small(false)
                      }}
                      color="danger"
                      type="button"
                    >
                      Cancel <i className="fas fa-times-circle"></i>
                    </Button>
                  </div>
                </Form>
              </Modal>
            </Container>
            <ToastContainer />
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default TermsConditions
