import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "Url"
import Select from "react-select"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const history = useHistory()

  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("franchiseCode", form.franchiseCode)
    dataArray.append("companyName", form.companyName)
    dataArray.append("companyPhone", form.companyPhone)
    dataArray.append("companyGstNumber", form.companyGstNumber)
    dataArray.append("address", form.address)
    dataArray.append("supportEmail", form.supportEmail)
    dataArray.append("bookingEmail", form.bookingEmail)
    dataArray.append("userName", form.userName)
    dataArray.append("email", form.email)
    dataArray.append("phone", form.phone)
    dataArray.append("stateId", form.stateId)
    dataArray.append("districtId", form.districtId)
    dataArray.append("cityId", form.cityId)
    dataArray.append("pincode", JSON.stringify(selectedOptions))
    dataArray.append("bankName", form.bankName)
    dataArray.append("razoraccountId", form.razoraccountId)
    dataArray.append("accountHolderName", form.accountHolderName)
    dataArray.append("bankAccountNumber", form.bankAccountNumber)
    dataArray.append("ifscCode", form.ifscCode)
    dataArray.append("bankAccountType", form.bankAccountType)
    dataArray.append("panNumber", form.panNumber)

    for (let i = 0; i < selectedFiles1.length; i++) {
      dataArray.append("companyIdImage", selectedFiles1[i])
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("companyLogo", selectedFiles[i])
    }

    axios
      .put(URLS.UpdateFranchise + form._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Franchiselist")
            sessionStorage.setItem(
              "tost",
              "Franchise has been updated successfully"
            )
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const handleDrop1 = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const fileExtension = file.name.split(".").pop().toLowerCase()
      const allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"]

      if (!allowedExtensions.includes(fileExtension)) {
        toast("Invalid file format! Only JPG, JPEG, PNG are allowed.")
        return
      }

      const validatedFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize1(file.size),
      })

      setselectedFiles([validatedFile])
    }
  }

  const formatFileSize1 = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const handleDrop2 = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const fileExtension = file.name.split(".").pop().toLowerCase()
      const allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"]

      if (!allowedExtensions.includes(fileExtension)) {
        toast("Invalid file format! Only JPG, JPEG, PNG are allowed.")
        return
      }

      const validatedFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize2(file.size),
      })

      setselectedFiles1([validatedFile])
    }
  }

  const formatFileSize2 = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const [states, setstates] = useState([])

  const [District, setDistrict] = useState([])

  const [City, setCity] = useState([])

  useEffect(() => {
    getallStates()
  }, [])

  const getallStates = () => {
    var token = datas
    axios
      .post(URLS.GetActiveStates, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setstates(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetActiveDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.Districts)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getCity(e.target.value)
  }

  const getCity = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetActiveCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.cityResult)
      })
  }

  useEffect(() => {
    getPlansid()
  }, [])

  const [Pincode, setPincode] = useState([])

  useEffect(() => {
    GetPincodes()
  }, [])

  const GetPincodes = () => {
    var token = datas
    axios
      .post(
        URLS.GetPincode,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setPincode(res.data.pincodes)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [selectedOptions, setSelectedOptions] = useState([])

  const Categoryss = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  const options = Pincode.map(response => ({
    value: response._id,
    label: response.postalCode,
  }))

  const plansid = sessionStorage.getItem("planid")

  const getPlansid = () => {
    const dataArray = new FormData()
    dataArray.append("_id", plansid)

    var token = datas
    axios
      .post(URLS.getFranchiseid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.franchResult)
        setSelectedOptions(res.data.franchResult.pincode)

        const as = res.data.franchResult.stateId
        const as1 = res.data.franchResult.districtId

        var token = datas
        const dataArray = new FormData()
        dataArray.append("stateId", as)
        axios
          .post(URLS.GetActiveDistrict, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setDistrict(res.data.Districts)
          })

        var token = datas

        const dataArray1 = new FormData()
        dataArray1.append("districtId", as1)
        axios
          .post(URLS.GetActiveCity, dataArray1, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setCity(res.data.cityResult)
          })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Kanavneer Admin"
            breadcrumbItem="Edit Franchise"
          />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#0e4875", fontWeight: "bold" }}
                    >
                      Franchise Information
                    </h5>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Franchise Id <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Franchise Id"
                        required
                        value={form.franchiseCode}
                        name="franchiseCode"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Franchise Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Franchise Name"
                        required
                        value={form.companyName}
                        name="companyName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Franchise Phone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Franchise Phone"
                        required
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]+"
                        value={form.companyPhone}
                        name="companyPhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Franchise Booking Email{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Booking Email"
                        required
                        value={form.bookingEmail}
                        name="bookingEmail"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Support Email
                      </Label>
                      <Input
                        type="email"
                        required
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Support Email"
                        value={form.supportEmail}
                        name="supportEmail"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Franchise Logo</h5>

                      <div className="w-50 m-auto">
                        <Dropzone onDrop={handleDrop1}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                {...getRootProps()}
                                className="dz-message needsclick mt-2"
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Upload Image.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}

                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col>
                                <img
                                  data-dz-thumbnail=""
                                  height="50px"
                                  className="rounded bg-light"
                                  alt={form.name}
                                  src={URLS.Base + form.companyLogo}
                                />
                              </Col>
                            </Row>
                          </div>

                          <span>
                            Image format - jpg, png, jpeg, gif Image Size -
                            maximum size 2 MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <h5
                    className="mb-4"
                    style={{ color: "#0e4875", fontWeight: "bold" }}
                  >
                    Address Information
                  </h5>
                  <Col md="4">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select state <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.stateId}
                        name="stateId"
                        onChange={e => {
                          Optionchange(e)
                        }}
                        id="ss"
                        className="form-select"
                        required
                      >
                        <option value="">Select</option>
                        {states.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md="4">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select District <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.districtId}
                        name="districtId"
                        onChange={e => {
                          Optionchange1(e)
                        }}
                        id="ss"
                        className="form-select"
                        required
                      >
                        <option value="">Select</option>
                        {District.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md="4">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select City <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.cityId}
                        name="cityId"
                        onChange={e => {
                          handlechange(e)
                        }}
                        id="ss"
                        className="form-select"
                        required
                      >
                        <option value="">Select</option>
                        {City.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md="4">
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        PinCode
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        options={options}
                        placeholder="Enter PinCode"
                        value={selectedOptions}
                        onChange={Categoryss}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                  </Col>{" "}
                  <Col md="8">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="2"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Address"
                        required
                        value={form.address}
                        name="address"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row className="mt-2">
              <Col lg="6">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-2"
                      style={{ color: "#0e4875", fontWeight: "bold" }}
                    >
                      Business Information
                    </h5>

                    <div className="mb-5">
                      <Label for="basicpill-firstname-input1">
                        Gst Number<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Gst Number"
                        required
                        value={form.companyGstNumber}
                        name="companyGstNumber"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-1 mt-3">
                      <h5 style={{ fontWeight: "bold" }}>
                        Franchise Documents
                      </h5>
                      <div className="w-50">
                        <Dropzone onDrop={handleDrop2}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                {...getRootProps()}
                                className="dz-message needsclick mt-2"
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Upload Image.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles1.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col>
                                <img
                                  data-dz-thumbnail=""
                                  height="50px"
                                  className="rounded bg-light"
                                  alt={form.name}
                                  src={URLS.Base + form.companyIdImage}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <Row>
                      <h5
                        className="mb-2"
                        style={{ color: "#0e4875", fontWeight: "bold" }}
                      >
                        Personal Information
                      </h5>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Franchise Person Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Person Name"
                            required
                            value={form.userName}
                            name="userName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Franchise Person Phone{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Phone"
                        required
                        value={form.phone}
                        name="phone"
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]+"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <h5
                      className="mb-2"
                      style={{ color: "#0e4875", fontWeight: "bold" }}
                    >
                      Account Information
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Email
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Email"
                        required
                        value={form.email}
                        name="email"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <h5
                        className="mb-3"
                        style={{ color: "#0e4875", fontWeight: "bold" }}
                      >
                        Bank Details
                      </h5>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Razorpay Account Id{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Razorpay Account Id"
                            required
                            value={form.razoraccountId}
                            name="razoraccountId"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Bank Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Bank Name"
                            required
                            value={form.bankName}
                            name="bankName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Holder Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Holder Name"
                            required
                            value={form.accountHolderName}
                            name="accountHolderName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Bank Account Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Bank Account Number"
                            required
                            value={form.bankAccountNumber}
                            name="bankAccountNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            IFSC Code <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter IFSC Code"
                            required
                            value={form.ifscCode}
                            name="ifscCode"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Bank Account Type
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            className="form-select"
                            required
                            value={form.bankAccountType}
                            name="bankAccountType"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value=""> Select </option>
                            <option value="CurrentAccount">
                              Current Account
                            </option>
                            <option value="SavingAccount">
                              Saving Account
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Pan Number <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Pan Number"
                            required
                            value={form.panNumber}
                            name="panNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default AddVendors
