import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button, Table, Spinner } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"

function ViewFranchise() {
  const [plans, setplans] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [inputList, setInputList] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getPlans()
  }, [])

  const plansid = sessionStorage.getItem("Regularplanid")

  const getPlans = () => {
    const dataArray = new FormData()
    dataArray.append("_id", plansid)

    var token = datas
    axios
      .post(URLS.getRegularPlanid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setIsLoading(false)
        setplans(res.data.planResult[0])
        setInputList(res.data.planResult[0].planAdvantages)
      })
  }

  const history = useHistory()
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Kanavneer Admin"
            breadcrumbItem="View Regular Plan"
          />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <Spinner
                  style={{ marginTop: "20px" }}
                  className="ms-2"
                  animation="border"
                  color="primary"
                  size="lg"
                />{" "}
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col md={4}>
                      <ul className="list-unstyled vstack gap-3 mb-0">
                        <h5 className="mb-4 text-primary mb-4">
                          Plan Details :-
                        </h5>
                        <a
                          href={URLS.Base + plans.planImage}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={URLS.Base + plans.planImage}
                            width="100px"
                            height="100px"
                          ></img>
                        </a>
                        <li>
                          <div className="d-flex mt-4">
                            <i className="bx bxs-check-shield font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold">Plan Name : </h6>
                              <span className="text-muted">
                                {plans.planType}{" "}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col md={8}>
                      <h5 className="mb-4 text-primary mb-5">
                        Plan Information :-
                      </h5>
                      <div className="table-rep-plugin mt-5 table-responsive">
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>Advantages</th>
                              <th>Info</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputList.map((data, i) => (
                              <tr key={i} className="text-center">
                                <td>{data.advantage}</td>
                                <td>{data.info}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-5">
                <CardBody>
                  <Row className="mb-5">
                    <Col lg={12}>
                      <h4 className="mb-4 text-primary">Plan Benifits :-</h4>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered ">
                          <thead>
                            <tr className="text-center text-white bg-primary">
                              <th>Benifits</th>
                              <th>1 month</th>
                              <th>3 months</th>
                              <th>6 months</th>
                              <th>12 months</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="text-center">
                              <td style={{ fontWeight: "bold" }}>Recharge</td>
                              <td>{plans.oneMonthPrice}</td>
                              <td>{plans.threeMonthPrice}</td>
                              <td>{plans.sixMonthPrice}</td>
                              <td>{plans.twelveMonthPrice}</td>
                            </tr>
                            <tr className="text-center">
                              <td style={{ fontWeight: "bold" }}>Bonus Days</td>
                              <td>{plans.oneMonthBonusDays}</td>
                              <td>{plans.threeMonthBonusDays}</td>
                              <td>{plans.sixMonthBonusDays}</td>
                              <td>{plans.twelveMonthBonusDays}</td>
                            </tr>
                            <tr className="text-center">
                              <td style={{ fontWeight: "bold" }}>
                                Bonus Liters
                              </td>
                              <td>{plans.oneMonthBonusLitrs}</td>
                              <td>{plans.threeMonthBonusLitrs}</td>
                              <td>{plans.sixMonthBonusLitrs}</td>
                              <td>{plans.twelveMonthBonusLitrs}</td>
                            </tr>
                            <tr className="text-center">
                              <td style={{ fontWeight: "bold" }}>
                                Total Liters
                              </td>
                              <td>{plans.oneMonthTotalLitrs}</td>
                              <td>{plans.threeMonthTotalLitrs}</td>
                              <td>{plans.sixMonthTotalLitrs}</td>
                              <td>{plans.twelveMonthTotalLitrs}</td>
                            </tr>
                          </tbody>
                          {/* <tfoot>
                        <tr className="text-center bg-success text-white">
                          <td style={{ fontWeight: "bold" }}>Total Saving</td>
                          <td style={{ fontWeight: "bold" }}>
                            {plans.threeMonthDiscount} /-
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {plans.sixMonthDiscount} /-
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {plans.twelveMonthDiscount} /-
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {plans.twelveMonthDiscount} /-
                          </td>
                        </tr>
                      </tfoot> */}
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewFranchise
