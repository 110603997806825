const Url = "https://api.kanavneer.com/"

export const URLS = {
  Base: Url,

  forget: Url + "v1/kanavneelapi/admin/auth/sendotpemail",
  OTP: Url + "v1/kanavneelapi/admin/auth/compareOtp",
  Resetpass: Url + "v1/kanavneelapi/admin/auth/resetpassword",

  //settings/state
  AddState: Url + "v1/kanavneelapi/admin/state/addstate",
  GetState: Url + "v1/kanavneelapi/admin/state/getall",
  GetStateSearch: Url + "v1/kanavneelapi/admin/state/getall?searchQuery=",
  UpdateState: Url + "v1/kanavneelapi/admin/state/editstate",
  InActiveState: Url + "v1/kanavneelapi/admin/state/deletestate/",

  //settings/District
  AddDistrict: Url + "v1/kanavneelapi/admin/district/adddist",
  GetDistrict: Url + "v1/kanavneelapi/admin/district/getall",
  GetDistrictSearch: Url + "v1/kanavneelapi/admin/district/getall?searchQuery=",
  UpdateDistrict: Url + "v1/kanavneelapi/admin/district/editdist",
  InActiveDistrict: Url + "v1/kanavneelapi/admin/district/deletedist",
  GetIdDistrict: Url + "v1/kanavneelapi/admin/district/getallbystateid",

  //settings/City
  AddCity: Url + "v1/kanavneelapi/admin/city/addcity",
  GetCity: Url + "v1/kanavneelapi/admin/city/getall",
  GetCitySearch: Url + "v1/kanavneelapi/admin/city/getall?searchQuery=",
  UpdateCity: Url + "v1/kanavneelapi/admin/city/editcity",
  InActiveCity: Url + "v1/kanavneelapi/admin/city/deletecity",
  GetIdCity: Url + "v1/kanavneelapi/admin/city/getallbydistid",

  //settings/Pincode
  AddPincode: Url + "v1/kanavneelapi/admin/pincode/addpincode",
  GetPincode: Url + "v1/kanavneelapi/admin/pincode/getall",
  GetPincodeSearch: Url + "v1/kanavneelapi/admin/pincode/getall?searchQuery=",
  UpdatePincode: Url + "v1/kanavneelapi/admin/pincode/editpincode",
  InActivePincode: Url + "v1/kanavneelapi/admin/pincode/deletepincode/",

  //profile
  getProfile: Url + "v1/kanavneelapi/admin/auth/getprofile",
  UpdateProfile: Url + "v1/kanavneelapi/admin/auth/updateprofile",
  UpdateImage: Url + "v1/kanavneelapi/admin/auth/updateprofileimage",
  ChangePass: Url + "v1/kanavneelapi/admin/auth/changepass",

  //Plans
  AddPlan: Url + "v1/kanavneelapi/admin/launchplan/addplan",
  GetPlan: Url + "v1/kanavneelapi/admin/launchplan/getall",
  GetPlansearch: Url + "v1/kanavneelapi/admin/launchplan/getall?searchQuery=",
  UpdatePlan: Url + "v1/kanavneelapi/admin/launchplan/editplan/",
  DeletePlan: Url + "v1/kanavneelapi/admin/launchplan/editplanstatus/",
  getplanid: Url + "v1/kanavneelapi/admin/launchplan/getdetails",

  //AddRegularPlans
  AddAddRegularPlan: Url + "v1/kanavneelapi/admin/regularplan/addplan",
  GetRegularPlan: Url + "v1/kanavneelapi/admin/regularplan/getall",
  GetRegularPlansearch:
    Url + "v1/kanavneelapi/admin/regularplan/getall?searchQuery=",
  UpdateARegularPlan: Url + "v1/kanavneelapi/admin/regularplan/editplan/",
  DeleteRegularPlan: Url + "v1/kanavneelapi/admin/regularplan/editplanstatus/",
  getRegularPlanid: Url + "v1/kanavneelapi/admin/regularplan/getdetails",

  //States
  GetActiveStates: Url + "v1/kanavneelapi/admin/state/getallfordropdown",
  GetActiveDistrict: Url + "v1/kanavneelapi/admin/district/getallbystateid",
  GetActiveCity: Url + "v1/kanavneelapi/admin/city/getallbydistid",

  //Plans
  AddFranchise: Url + "v1/kanavneelapi/admin/franchise/addfranchise",
  GetFranchise: Url + "v1/kanavneelapi/admin/franchise/getall",
  UpdateFranchise: Url + "v1/kanavneelapi/admin/franchise/editfrach/",
  DeleteFranchise: Url + "v1/kanavneelapi/admin/franchise/editfrachstatus/",
  getFranchiseid: Url + "v1/kanavneelapi/admin/franchise/getdetails",
  getFranchiseidsearch:
    Url + "v1/kanavneelapi/admin/franchise/getall?searchQuery=",

  //Customers
  AddCustomers: Url + "v1/kanavneelapi/admin/customer/addcustomer",
  GetCustomers: Url + "v1/kanavneelapi/admin/customer/getall",
  UpdateCustomers: Url + "v1/kanavneelapi/admin/customer/edit/",
  DeleteCustomers: Url + "v1/kanavneelapi/admin/customer/delete/",
  getCustomersid: Url + "v1/kanavneelapi/admin/customer/getdetails",
  getCustomersidsearch:
    Url + "v1/kanavneelapi/admin/customer/getall?searchQuery=",

  //serviceman
  GetServiceman: Url + "v1/kanavneelapi/admin/serviceman/getall",
  GetServicemanSearch:
    Url + "v1/kanavneelapi/admin/serviceman/getall?searchQuery=",
  AddServiceman: Url + "v1/kanavneelapi/admin/serviceman/addservman",
  EditServiceman: Url + "v1/kanavneelapi/admin/serviceman/edit/",
  GetOneServiceman: Url + "v1/kanavneelapi/admin/serviceman/getdetails",
  DeleteServiceman: Url + "v1/kanavneelapi/admin/serviceman/delete/",
  statusServiceman: Url + "v1/kanavneelapi/admin/serviceman/updatestatus/",
  AssignServiceman: Url + "v1/kanavneelapi/admin/serviceman/assigntofranchise/",

  //Bookings
  GetpendingBooking: Url + "v1/kanavneelapi/admin/booking/getallpending",
  GetpendingBookingSearch:
    Url + "v1/kanavneelapi/admin/booking/getallpending?searchQuery=",

  GetacceptedBooking: Url + "v1/kanavneelapi/admin/booking/getallaccepted",
  GetacceptedSearch:
    Url + "v1/kanavneelapi/admin/booking/getallaccepted?searchQuery=",

  Getgetallassigned: Url + "v1/kanavneelapi/admin/booking/getallassigned",
  GetgetallassignedSearch:
    Url + "v1/kanavneelapi/admin/booking/getallassigned?searchQuery=",

  GetgetallinprogressBooking:
    Url + "v1/kanavneelapi/admin/booking/getallinprogress",
  GetgetallinprogressBookingSearch:
    Url + "v1/kanavneelapi/admin/booking/getallinprogress?searchQuery=",

  Getgetallcompleted: Url + "v1/kanavneelapi/admin/booking/getallcompleted",
  GetgetallcompletedSearch:
    Url + "v1/kanavneelapi/admin/booking/getallcompleted?searchQuery=",

  GetcanceledBooking: Url + "v1/kanavneelapi/admin/booking/getallcanceled",
  GetcanceledBookingSearch:
    Url + "v1/kanavneelapi/admin/booking/getallcanceled?searchQuery=",

  //Services
  GetcompletedServices: Url + "v1/kanavneelapi/admin/service/getallcompleted",
  GetcompletedServicesSearch:
    Url + "v1/kanavneelapi/admin/service/getallcompleted?searchQuery=",

  GetpendingServices: Url + "v1/kanavneelapi/admin/service/getallpending",
  GetpendingServicesSearch:
    Url + "v1/kanavneelapi/admin/service/getallpending?searchQuery=",

  GetCancelledBookings: Url + "v1/kanavneelapi/admin/booking/getallcanceled",
  GetCancelledBookingsSearch:
    Url + "v1/kanavneelapi/admin/booking/getallcanceled?searchQuery=",

  GetCancelledServices: Url + "v1/kanavneelapi/admin/complaint/getallrejected",
  GetCancelledServicesSearch:
    Url + "v1/kanavneelapi/admin/complaint/getallrejected?searchQuery=",

  //Coupons
  AddCoupon: Url + "v1/kanavneelapi/admin/coupon/addcpn",
  GetCoupon: Url + "v1/kanavneelapi/admin/coupon/getall",
  UpdateCoupon: Url + "v1/kanavneelapi/admin/coupon/editcpn",
  DeleteCoupon: Url + "v1/kanavneelapi/admin/coupon/deletecpn",
  getCouponsearch: Url + "v1/kanavneelapi/admin/coupon/getall?searchQuery=",

  //PaymentGetway
  GetPaymentGetway: Url + "v1/kanavneelapi/admin/paymentgateway/getdetails",
  UpdateGetPayment: Url + "v1/kanavneelapi/admin/paymentgateway/edit",

  //business Setting
  GetBusinessSetting: Url + "v1/kanavneelapi/admin/businesssetting/getdetails",
  GetBusinessSettingSearch:
    Url + "v1/kanavneelapi/admin/businesssetting/getdetails?searchQuery=",
  UpdateBusinessSetting: Url + "v1/kanavneelapi/admin/businesssetting/edit",

  //Faqs
  AddFAQ: Url + "v1/kanavneelapi/admin/faq/addfaq",
  UpdateFAQ: Url + "v1/kanavneelapi/admin/faq/editfaq",
  DeleteFAQ: Url + "v1/kanavneelapi/admin/faq/deletefaq",
  GetFAQ: Url + "v1/kanavneelapi/admin/faq/getall",
  GetSetting: Url + "v1/kanavneelapi/admin/setting/getsetting",

  //User
  UpdatetermsAndCondition: Url + "v1/kanavneelapi/admin/setting/edittermscond",
  UpdaterefundPolicy: Url + "v1/kanavneelapi/admin/setting/editrefundpolicy",
  UpdateprivacyPolicy: Url + "v1/kanavneelapi/admin/setting/editprivacypolicy",
  UpdateaboutUs: Url + "v1/kanavneelapi/admin/setting/editabout",

  //Vendor
  UpdateVendortermsAndCondition:
    Url + "v1/kanavneelapi/admin/setting/editvendortermscond",
  UpdateVendorrefundPolicy:
    Url + "v1/kanavneelapi/admin/setting/editvendorrefundpolicy",
  UpdateVendorprivacyPolicy:
    Url + "v1/kanavneelapi/admin/setting/editvendorprivacypolicy",

  //Service Man
  GetServiceManSetting:
    Url + "v1/kanavneelapi/admin/servicemansetting/getsetting",
  UpdateServiceMantermsAndCondition:
    Url + "v1/kanavneelapi/admin/servicemansetting/updatetermscond",
  UpdateServiceManprivacyPolicy:
    Url + "v1/kanavneelapi/admin/servicemansetting/updateprivacy",

  //Banner
  AddBanners: Url + "v1/kanavneelapi/admin/banner/addbanner",
  UpdateBanners: Url + "v1/kanavneelapi/admin/banner/editbanner/",
  GetBanners: Url + "v1/kanavneelapi/admin/banner/getall",
  InActiveBanners: Url + "v1/kanavneelapi/admin/banner/deletebanner/",
  GetBannersSearch: Url + "v1/kanavneelapi/admin/banner/getall?searchQuery=",

  WebBanners: Url + "v1/kanavneelapi/admin/webbanner/getbanner",
  WebupdateBanners: Url + "v1/kanavneelapi/admin/webbanner/updatebanner",

  //SpareParts
  AddSpareParts: Url + "v1/kanavneelapi/admin/filterparts/addpart",
  UpdateSpareParts: Url + "v1/kanavneelapi/admin/filterparts/editpart/",
  GetSpareParts: Url + "v1/kanavneelapi/admin/filterparts/getall",
  InActiveSpareParts: Url + "v1/kanavneelapi/admin/filterparts/deletepart/",
  GetSparePartsSearch:
    Url + "v1/kanavneelapi/admin/filterparts/getall?searchQuery=",

  //CompleteBox
  GetPendingCompleteBox: Url + "v1/kanavneelapi/admin/complaint/getallpending",
  GetPendingCompleteBoxSearch:
    Url + "v1/kanavneelapi/admin/complaint/getallpending?searchQuery=",
  GetAcceptedCompleteBox:
    Url + "v1/kanavneelapi/admin/complaint/getallaccepted",
  GetAcceptedCompleteBoxSearch:
    Url + "v1/kanavneelapi/admin/complaint/getallaccepted?searchQuery=",
  GetInProgressCompleteBox:
    Url + "v1/kanavneelapi/admin/complaint/getallinprogress",
  GetInProgressCompleteBoxSearch:
    Url + "v1/kanavneelapi/admin/complaint/getallinprogress?searchQuery=",
  GetCompletedCompleteBox:
    Url + "v1/kanavneelapi/admin/complaint/getallcompleted",
  GetCompletedCompleteBoxSearch:
    Url + "v1/kanavneelapi/admin/complaint/getallcompleted?searchQuery=",
  UpdateCompleteBox: Url + "v1/kanavneelapi/admin/complaint/updatestatus/",

  //Reports
  servicemanReport: Url + "v1/kanavneelapi/admin/report/servicemanreport",
  customerReport: Url + "v1/kanavneelapi/admin/report/customerreport",
  franchiseReport: Url + "v1/kanavneelapi/admin/report/franchisereport",
  serviceReport: Url + "v1/kanavneelapi/admin/report/servicereport",
  BookingReport: Url + "v1/kanavneelapi/admin/report/bookingreport",
  ExpireReport: Url + "v1/kanavneelapi/admin/report/bookingexpiringreport",
  ExpiredReport: Url + "v1/kanavneelapi/admin/report/bookingexpiredreport",
  PaymentesReport: Url + "v1/kanavneelapi/admin/report/paymentReport",
  PaymentesReport: Url + "v1/kanavneelapi/admin/report/paymentReport",

  AddNotification:
    Url + "v1/kanavneelapi/admin/commonnotification/addnotification",
  GetNotification: Url + "v1/kanavneelapi/admin/commonnotification/getall",
  DeleteNotification: Url + "v1/kanavneelapi/admin/commonnotification/delete/",
  GetNotificationSearch:
    Url + "v1/kanavneelapi/admin/commonnotification/getall?searchQuery=",

  //ComplaintBox
  GetComplaint: Url + "v1/kanavneelapi/admin/complaint/getall",
  GetcomplaintSearch:
    Url + "v1/kanavneelapi/admin/complaint/getall?searchQuery=",

  //Dashboard
  GetDashboard: Url + "v1/kanavneelapi/admin/auth/getdashboard",

  UpdateBookingStatus: Url + "v1/kanavneelapi/admin/booking/updateStatusOrder",
  AssignServicemanBookings:
    Url + "v1/kanavneelapi/admin/booking/assignServicemanToBooking",

  ViewBookingId: Url + "v1/kanavneelapi/admin/booking/getplanpurchasebyid",

  ViewServiceBookingId:
    Url + "v1/kanavneelapi/admin/complaint/getcomplaintbyid",

  AssignServicemanService:
    Url + "v1/kanavneelapi/admin/assignservicemantocomplaint",

  UpdateServiceStatus: Url + "v1/kanavneelapi/admin/updatestatuscomplaint",

  //Subscription List
  SubscriptionList:
    Url + "v1/kanavneelapi/admin/subscription/getUserPlanDetails",
  SubscriptionListSearch:
    Url + "v1/kanavneelapi/admin/subscription/getUserPlanDetails?searchQuery=",
  GetBuySubscribeId:
    Url + "v1/kanavneelapi/admin/subscription/getUserPlanDetailsById",

  GetCountList: Url + "v1/kanavneelapi/admin/complaint/getCount",

  //DiviceManagment
  UpdateDiviceManagment:
    Url + "v1/kanavneelapi/admin/franchise/updateCountToFranchise",
  GetDiviceManagment:
    Url + "v1/kanavneelapi/admin/franchise/getAddedCountLogs?searchQuery=",
  GetDiviceManagmentsearch:
    Url + "v1/kanavneelapi/admin/franchise/getAddedCountLogs?searchQuery=",
  UpdateDivicesManagment:
    Url + "v1/kanavneelapi/admin/franchise/editCountToFranchise",

    // Get Enquaries
    Getallenquiries: Url + "v1/kanavneelapi/admin/getallenquiries",
    Getallenquiriessearch: Url + "v1/kanavneelapi/admin/getallenquiries?searchQuery=",
    Deleteenquiries: Url + "v1/kanavneelapi/admin/deleteenquiry/",
}
