import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPassword from "pages/Authentication/ForgetPassword"
import Resetpsw from "pages/Authentication/Resetpsw"
import Compareotp from "pages/Authentication/Compareotp"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Franchiselist
import Franchiselist from "pages/Franchise/Franchiselist"
import AddFranchise from "pages/Franchise/AddFranchise"
import EditFranchise from "pages/Franchise/EditFranchise"

//PlansList
import Addplan from "pages/Plans/Addplan"
import PlansList from "pages/Plans/PlansList"
import Editplan from "pages/Plans/Editplan"

//Coupon
import Coupon from "pages/Coupon"

//Serviceman
import ServiceMan from "pages/Serviceman/ServiceMan"

//ComplaintBox
import Complaintbox from "pages/Complaintbox"

//Device
import AddDeviceManagment from "pages/Device/AddDeviceManagment"
import DeviceManagment from "pages/Device/DeviceManagment"

//Customers
import AddCustomer from "pages/Customers/AddCustomer"
import Customeslist from "pages/Customers/Customeslist"
import EditCustomer from "pages/Customers/EditCustomer"

//Settings
import PaymentGetway from "pages/Getway/PaymentGetway"
import SmsGetway from "pages/Getway/SmsGetway"
import State from "pages/Settings/State"
import City from "pages/Settings/City"
import District from "pages/Settings/District"
import RefundPolicy from "pages/Settings/RefundPolicy"
import Terms from "pages/Settings/Terms"
import Enquiries from "pages/Settings/Enquiries"
import About from "pages/Settings/About"
import Faqs from "pages/Settings/Faqs"
import PrivacyPolicy from "pages/Settings/PrivacyPolicy"
import BusinessSettings from "pages/Getway/BusinessSettings"

//Reports
import Customerwisereport from "pages/Reports/Customerwisereport"
import ServicemanwiseReport from "pages/Reports/ServicemanwiseReport"
import FranchisewiseReport from "pages/Reports/FranchisewiseReport"
import Bookingreport from "pages/Reports/Bookingreport"
import ServiceRepot from "pages/Reports/ServiceRepot"

//Bookings
import Bookinglist from "pages/Bookinglist/Bookinglist"
import BookingDetails from "pages/Bookinglist/BookingDetails"
import PendingBookings from "pages/Bookinglist/PendingBookings"
import Acceptedbookings from "pages/Bookinglist/Acceptedbookings"
import Compleatedbookings from "pages/Bookinglist/Compleatedbookings"
import Inprogessbookings from "pages/Bookinglist/Inprogessbookings"
import Assignbookings from "pages/Bookinglist/Assignbookings"
import Cancelledbookings from "pages/Bookinglist/Cancelledbookings"

import DeleteServiceManAccount from "pages/DeleteServiceManAccount"
import DeleteUserAccount from "pages/DeleteUserAccount"

//Banner
import Banner from "pages/Banner"
import Webbanners from "pages/Webbanners"

import Notifications from "pages/Notifications"

import ExpiredReport from "pages/Reports/ExpiredReport"

import Expirereport from "pages/Reports/Expirereport"

import ViewFranchise from "pages/Franchise/ViewFranchise"

import RejectComplaintbox from "pages/RejectComplaintbox"

import ResolvedComplaintbox from "pages/ResolvedComplaintbox"

import AddRegularPlans from "pages/Plans/AddRegularPlans"
import EditRegularPlans from "pages/Plans/EditRegularPlans"
import RegularPlans from "pages/Plans/RegularPlans"

import Pincode from "pages/Settings/Pincode"

import AddServiceMan from "pages/Serviceman/AddServiceMan"
import EditServiceMan from "pages/Serviceman/EditServiceMan"

import ViewCustomers from "pages/Customers/ViewCustomers"
import ViewServiceMan from "pages/Serviceman/ViewServiceMan"

import PlanView from "pages/Plans/PlanView"
import ViewRegularPlan from "pages/Plans/ViewRegularPlan"

import AssignedComplaintbox from "pages/AssignedComplaintbox"
import InProgessComplaintbox from "pages/InProgessComplaintbox"

import ViewRegistationBookings from "pages/Bookinglist/ViewRegistationBookings"

import ViewComplaintbox from "pages/ViewComplaintbox"

import RegularPlanSubscription from "pages/Subscription/RegularPlanSubscription"
import ExpireRegularPlan from "pages/Subscription/ExpireRegularPlan"
import LaunchingPlanSubscription from "pages/Subscription/LaunchingPlanSubscription"
import ExpireLaunchingPlanSubscription from "pages/Subscription/ExpireLaunchingPlanSubscription"
import ViewSubscription from "pages/Subscription/ViewSubscription"

import PaymentReport from "pages/Reports/PaymentsReports"

import SpareParts from "pages/Settings/SpareParts"

import ViewRegularSubscription from "pages/Subscription/ViewRegularSubscription"

import CancellationService from "pages/CancellationService"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/RegularPlanSubscription", component: RegularPlanSubscription },
  { path: "/ExpireRegularPlan", component: ExpireRegularPlan },
  { path: "/LaunchingPlanSubscription", component: LaunchingPlanSubscription },
  {
    path: "/ExpireLaunchingPlanSubscription",
    component: ExpireLaunchingPlanSubscription,
  },
  { path: "/ViewSubscription", component: ViewSubscription },

  { path: "/ViewRegularSubscription", component: ViewRegularSubscription },

  { path: "/CancellationService", component: CancellationService },

  { path: "/PaymentReport", component: PaymentReport },

  { path: "/SpareParts", component: SpareParts },

  { path: "/AddServiceMan", component: AddServiceMan },
  { path: "/EditServiceMan", component: EditServiceMan },

  { path: "/ViewComplaintbox", component: ViewComplaintbox },

  { path: "/AssignedComplaintbox", component: AssignedComplaintbox },
  { path: "/InProgessComplaintbox", component: InProgessComplaintbox },

  { path: "/ViewRegularPlan", component: ViewRegularPlan },
  { path: "/PlanView", component: PlanView },

  { path: "/ViewRegistationBookings", component: ViewRegistationBookings },

  { path: "/ViewCustomers", component: ViewCustomers },
  { path: "/ViewServiceMan", component: ViewServiceMan },

  { path: "/RejectComplaintbox", component: RejectComplaintbox },

  { path: "/ResolvedComplaintbox", component: ResolvedComplaintbox },

  { path: "/Notifications", component: Notifications },

  { path: "/Bookinglist", component: Bookinglist },

  { path: "/EditCustomer", component: EditCustomer },

  { path: "/Banner", component: Banner },
  { path: "/webbanners", component: Webbanners },

  { path: "/Pincode", component: Pincode },

  { path: "/ViewFranchise", component: ViewFranchise },

  { path: "/AddRegularPlans", component: AddRegularPlans },
  { path: "/EditRegularPlans", component: EditRegularPlans },
  { path: "/RegularPlans", component: RegularPlans },

  //Bookings
  { path: "/BookingDetails", component: BookingDetails },
  { path: "/PendingBookings", component: PendingBookings },
  { path: "/Acceptedbookings", component: Acceptedbookings },
  { path: "/Compleatedbookings", component: Compleatedbookings },
  { path: "/Inprogessbookings", component: Inprogessbookings },
  { path: "/Assignbookings", component: Assignbookings },
  { path: "/Cancelledbookings", component: Cancelledbookings },

  //Franchise
  { path: "/AddFranchise", component: AddFranchise },
  { path: "/Franchiselist", component: Franchiselist },
  { path: "/EditFranchise", component: EditFranchise },

  //Plans
  { path: "/Addplan", component: Addplan },
  { path: "/PlansList", component: PlansList },
  { path: "/Editplan", component: Editplan },

  //Serviceman
  { path: "/ServiceMan", component: ServiceMan },

  // //Coupon
  { path: "/Coupon", component: Coupon },

  //ComplaintBox
  { path: "/Complaintbox", component: Complaintbox },

  //Device
  { path: "/AddDeviceManagment", component: AddDeviceManagment },
  { path: "/DeviceManagment", component: DeviceManagment },

  //Device
  { path: "/AddDeviceManagment", component: AddDeviceManagment },
  { path: "/DeviceManagment", component: DeviceManagment },

  //Customers
  { path: "/AddCustomer", component: AddCustomer },
  { path: "/Customeslist", component: Customeslist },

  //Customers
  { path: "/AddCustomer", component: AddCustomer },
  { path: "/Customeslist", component: Customeslist },

  //Settings
  { path: "/PaymentGetway", component: PaymentGetway },
  { path: "/SmsGetway", component: SmsGetway },
  { path: "/State", component: State },
  { path: "/City", component: City },
  { path: "/District", component: District },
  { path: "/BusinessSettings", component: BusinessSettings },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Terms", component: Terms },
  { path: "/enquiries", component: Enquiries },
  { path: "/About", component: About },
  { path: "/Faqs", component: Faqs },
  { path: "/PrivacyPolicy", component: PrivacyPolicy },

  //Reports
  { path: "/Customerwisereport", component: Customerwisereport },
  { path: "/ServicemanwiseReport", component: ServicemanwiseReport },
  { path: "/FranchisewiseReport", component: FranchisewiseReport },
  { path: "/Bookingreport", component: Bookingreport },
  { path: "/ServiceRepot", component: ServiceRepot },
  { path: "/ExpiredReport", component: ExpiredReport },
  { path: "/Expirereport", component: Expirereport },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/ForgetPassword", component: ForgetPassword },
  { path: "/Resetpsw", component: Resetpsw },
  { path: "/Compareotp", component: Compareotp },
  { path: "/register", component: Register },
  { path: "/DeleteServiceManAccount", component: DeleteServiceManAccount },
  { path: "/DeleteUserAccount", component: DeleteUserAccount },
]

export { publicRoutes, authProtectedRoutes }
